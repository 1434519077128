import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Input, Label, Row, Spinner, UncontrolledAlert} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Link, useHistory} from "react-router-dom";
import {atom, useAtom} from "jotai";
import axiosInstance from "../../helpers/AxiosHelper";
import UiNotifications from "../Ui/ui-notifications";

export const bearerToken = atom(null);

const LoginComponent = () => {
    const history = useHistory();
    const [token, setToken] = useAtom(bearerToken);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [isCorrectCredentials, setIsCorrectCredentials] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isNetworkError, setIsNetworkError] = useState(false);

    // useEffect(() => {
    //     // const token = localStorage.getItem('token');
    //     if (!isLoading) {
    //         setTimeout(() => history.push("/tabel-candidati"), 2000)
    //     }
    // }, [isLoading]);

    function handleSubmit(e) {
        e.preventDefault()
        const data = JSON.stringify({
            "email": username,
            "password": password
        });
        try {
            axiosInstance.post('/api/v1/auth/authenticate', data, {
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                console.log(res.status)
                if (!(res.status === 200)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                const response = res.data;
                console.log(response)
                localStorage.setItem("token", response.token)
                // history.push("/tabel-candidati")
                setIsLoading(false);
                window.location.href = "/tabel-candidati";
            }).catch((error) => {
                setIsLoading(false);
                if (error.message === "Network Error") {
                    setIsNetworkError(true);
                }
                else if(error.response.status === 403) {
                    setIsCorrectCredentials(false)
                }

            })

        } catch(error) {
            console.log(error)
        }
    }

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}></Col>
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    {/*<div>*/}
                                                    {/*    <Link to="/" class="">*/}
                                                    {/*        <img src={logodark} alt="" height="20" class="auth-logo logo-dark mx-auto" />*/}
                                                    {/*        <img src={logolight} alt="" height="20" class="auth-logo logo-light mx-auto" />*/}
                                                    {/*    </Link>*/}
                                                    {/*</div>*/}

                                                    <h4 className="font-size-18 mt-4">PLATFORMA NEETS SUD MUNTENIA</h4>
                                                    <p className="text-muted">Introdu credentialele pentru conectare!</p>
                                                    {isLoading && (
                                                        <>
                                                            <Spinner className="me-2" color="primary" />
                                                            <p className="text-muted">Platforma se incarca...</p>
                                                        </>
                                                    )}
                                                </div>

                                                {!isCorrectCredentials && (
                                                    <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                                                        <i className="mdi mdi-block-helper me-2"></i>
                                                        Credentialele sunt incorecte!
                                                    </UncontrolledAlert>
                                                )}
                                                {isNetworkError && (
                                                    <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">
                                                        <i className="mdi mdi-block-helper me-2"></i>
                                                        Platforma nu este accesibila! Contactati echipa suport!
                                                    </UncontrolledAlert>
                                                )}

                                                {/*{this.props.loginError && this.props.loginError ? <Alert color="danger">{this.props.loginError}</Alert> : null}*/}

                                                <div className="p-2 mt-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="username">Email</Label>
                                                            <AvField name="username" type="text" className="form-control" id="username" validate={{ email: true, required: true }} placeholder="Introdu utilizatorul" onChange={(e) => setUsername(e.target.value)}/>
                                                        </div>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Parola</Label>
                                                            <AvField name="password" type="password" className="form-control" id="userpassword" placeholder="Introdu parola" onChange={(e) => setPassword(e.target.value)}/>
                                                        </div>

                                                        <div className="form-check">
                                                            <Input type="checkbox" className="form-check-input" id="customControlInline" />
                                                            <Label className="form-check-label" htmlFor="customControlInline">Retine datele</Label>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit" onClick={() => setIsLoading(true)}>Conectare</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i>Ai uitat parola?</Link>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        {/*<Col lg={8}>*/}
                        {/*    <div className="authentication-bg">*/}
                        {/*        <div className="bg-overlay"></div>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LoginComponent;
