import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table} from "reactstrap";
import CursuriBar from "./CursuriBar";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axiosInstance from "../../helpers/AxiosHelper";
import SecondaryNavbar from "../../components/HorizontalLayout/SecondaryNavbar";

const AlocareCursuri = () => {

    const [courses, setCourses] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [providers, setProviders] = useState([]);
    const [locations, setLocations] = useState([]);
    const [assignedCandidates, setAssignedCandidates] = useState([]);
    const [isProviderDisabled, setIsProviderDisabled] = useState(true);
    const [isLocationDisabled, setIsLocationDisabled] = useState(true);
    const [isSessionDisabled, setIsSessionDisabled] = useState(true);
    const [sessionsByLocation, setSessionsByLocation] = useState([]);

    const [formData, setFormData] = useState({
        curs: '',
        furnizor: '',
        locatie: '',
        sesiune: '',
    });
    let count = 0;
    let count2 = 0;
    const breadcrumbItems = [
        { title : "Tables", link : "#" },
        { title : "Responsive Table", link : "#" },
    ];

    useEffect(() => {
        getCourses();
        getCandidates();
    },[]);

    async function getCourses() {
        await axiosInstance.get('/api/course/all')
            .then(r => {
                setCourses(r.data);
                // setLoading(false);
            })
    }

    async function getCandidates() {
        await axiosInstance.get('/api/candidate/all')
            .then(r => {
                setCandidates(r.data);
                // setLoading(false);
            })
    }

    function handleSubmit() {

    }

    async function getSessions(providerId) {
        await axiosInstance.get('/api/session/course/id', {
            params: {
                cursId: formData.curs,
                furnizorId: providerId
            }
        })
            .then(r => {
                const response= r.data
                setSessions(response);
                setLocations([...new Set(response.map(session => session.locatie))])
                // console.log(r.data)
                // setLoading(false);
            })

    }

    async function getProviders(courseId) {
        await axiosInstance.get('/api/provider/course/id', {
            params: {
                id: courseId
            }
        })
            .then(r => {
                setProviders(r.data);
                // console.log(r.data)
                // setLoading(false);
            })
        setIsProviderDisabled(false);
    }

    // async function getSessionById(sessionId) {
    //     await axiosInstance.get('/api/session/id/', {
    //         params: {
    //             id: sessionId
    //         }
    //     })
    //         .then(r => {
    //             setSessions(r.data);
    //             console.log(r.data)
    //             // setLoading(false);
    //         })
    // }

    function onChangeInput(e) {
        if (e.target.type === "checkbox") {
            if (e.target.checked === true) {
                setAssignedCandidates(assignedCandidates => [...assignedCandidates, e.target.name])
            } else {
                const foundCandidateId = assignedCandidates.find(candidate => candidate === e.target.name);
                if (foundCandidateId !== undefined) {
                    setAssignedCandidates((assignedCandidates) => assignedCandidates.filter(candidate => candidate !== e.target.name));
                }
            }

        }
        setFormData({...formData, [e.target.name]: e.target.value});
        if (e.target.name === "curs") {
            getProviders(e.target.value)
        }
        else if (e.target.name === "furnizor") {
            getSessions(e.target.value)
            setIsLocationDisabled(false);
        }
        else if (e.target.name === "locatie") {
            const filteredSessions = sessions.filter(session => session.locatie === e.target.value);
            setSessionsByLocation(filteredSessions)
            setIsSessionDisabled(false);
        }
        // else if (e.target.name === "sesiune") {
        //
        //     // getProviders(e.target.value);
        //     // const foundSessionById = sessions.find(session => session.id.toString() === e.target.value);
        //     // setLocations([foundSessionById.locatie])
        // }

    }
    function verifyCourse() {
        const foundCourseA = courses.find(course => course.id.toString() === formData.curs.toString() && course.cursA === "da");
        if (foundCourseA !== undefined) {
            return 'A';
        }
        const foundCourseB = courses.find(course => course.id.toString() === formData.curs.toString() && course.cursB === "da");
        if (foundCourseB !== undefined) {
            return 'B';
        }
        const foundCourseC = courses.find(course => course.id.toString() === formData.curs.toString() && course.cursC === "da");
        if (foundCourseC !== undefined) {
            return 'C';
        }
        const foundCourseD = courses.find(course => course.id.toString() === formData.curs.toString() && course.cursD === "da");
        if (foundCourseD !== undefined) {
            return 'D';
        }
    }

    async function handleAssignation(e) {
        e.preventDefault()
        try {
            const response = await axiosInstance.post('/api/course/assign-candidates', assignedCandidates,{
                headers: {"Content-Type": "application/json"},
                params: {
                    courseId: formData.curs,
                    sessionId: formData.sesiune
                }
            })
            console.log(response)

        } catch(error) {
            console.log(error)
        }
        setIsSessionDisabled(true)
        setIsLocationDisabled(true)
        setIsProviderDisabled(true)
        setAssignedCandidates([])
        setFormData({
            ...formData,
            curs: '',
            furnizor: '',
            locatie: '',
            sesiune: '',
        });
        getCourses()
    }

    async function getCandidateCourses(e, candidateId) {
        await axiosInstance.get('/api/course/candidate/id', {
            params: {
                id: candidateId
            }
        })
            .then(r => {
                // return (r.data)
                console.log(r.data)
                // setLoading(false);
            })
    }

    async function getCourseName(courses) {
        console.log("cursurile : " + courses)
        const course = courses.find(element => element.principal === "da");
        if (course === undefined) {
            return `<td>---</td>`;
        }
        console.log("cursul : " + course.denumire)
        return `<td>{course.denumire}</td>`
    }

    function verifyCandidatePrincipalCourse(candidateCourses) {
        console.log(candidateCourses)
        const courseById = courses.find(course => course.id.toString() === formData.curs);
        if (courseById !== undefined) {
            console.log(courseById.principal)
            const candidateCourseYes = candidateCourses.find(course => course.principal === "da");
            if (candidateCourseYes !== undefined && courseById.principal === "da") {
                return false;
            }
            const candidateCourseNo = candidateCourses.find(course => course.principal === "nu");
            if (candidateCourseNo !== undefined && courseById.principal === "nu") {
                return false;
            }
        }
        return true;
    }

    function getDateFormat(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('ro-RO', {year: 'numeric', month: '2-digit', day: '2-digit'});
    }

    return (
        <React.Fragment>
            <SecondaryNavbar/>
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>
                    {/*<CursuriBar page="Alocare Cursuri"/>*/}
                    {/*<Breadcrumbs title="Adaugare / Editare cursuri" breadcrumbItems={breadcrumbItems} />*/}

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Breadcrumbs title="Alocare cursuri" breadcrumbItems={breadcrumbItems} />

                                    {/*<Link to="/formular-candidati"><h4 className="card-title">+Adauga</h4></Link>*/}
                                    {/*<p className="card-title-desc">This is an experimental awesome solution for responsive tables with complex data.</p>*/}
                                    <form onSubmit={handleSubmit}>
                                        <FormGroup row className="mb-2">
                                            <Label className="col-md-2 col-form-label">Selectie Curs</Label>
                                            <Col md={4}>
                                                <select className="form-select" name="curs" value={formData.curs} onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza curs</option>
                                                    {courses.map((item) => <option key={item.id} value={item.id}>{item.denumire}</option>)}
                                                </select>
                                            </Col>
                                        </FormGroup>

                                        {isProviderDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-2 col-form-label">Furnizor</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="furnizor" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza furnizor</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isProviderDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-2 col-form-label">Furnizor</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="furnizor" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Furnizor</option>
                                                        {providers.map((item) => <option value={item.id}>{item.nume}</option>)}
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                        {isLocationDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-2 col-form-label">Locatie</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="locatie" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza locatie</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isLocationDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-2 col-form-label">Locatie</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="locatie" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza locatie</option>
                                                        {locations.map((item) => <option value={item}>{item}</option>)}

                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                        {isSessionDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-2 col-form-label">Sesiune</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="sesiune" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza sesiune</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isSessionDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-2 col-form-label">Sesiune</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="sesiune" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza sesiune</option>
                                                        {sessionsByLocation.map((item) => <option value={item.id}>Sesiune( {getDateFormat(item.dataStart)} ... {getDateFormat(item.dataFinal)} )</option>)}
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                        <Row className="mb-3">
                                            {/*<Col xs={1}>Selectati: {assignedCandidates.length}</Col>*/}
                                            <Col xs={10}></Col>
                                            <Col xs={1} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                    onClick={handleAssignation}
                                                >
                                                    Aloca
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                    <div className="table-rep-plugin" style={{paddingTop: "30px"}}>
                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                            <Table id="tech-companies-1" striped bordered responsive>
                                                <thead>
                                                <tr>
                                                    <th className="dimenisune-col2">Selectati: {assignedCandidates.length}</th>
                                                    <th className="dimenisune-col">Cnp</th>
                                                    <th className="dimenisune-col">Nume</th>
                                                    <th className="dimenisune-col">Prenume</th>
                                                    <th className="dimenisune-col">Profil</th>
                                                    <th className="dimenisune-col">Curs principal</th>
                                                    <th className="dimenisune-col">Curs2</th>
                                                    <th className="dimenisune-col">Nr cursuri alocate</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>

                                                </tr>
                                                {formData.curs !== '' && candidates.map((candidate) => {
                                                    if (candidate.profil >= verifyCourse() &&
                                                        candidate.courses.length !== 2 &&
                                                        verifyCandidatePrincipalCourse(candidate.courses)
                                                    ) {
                                                        console.log(candidate)
                                                        return (
                                                            <tr key={candidate.id}>
                                                                <td>
                                                                    <div className="form-check mb-3">
                                                                        <Input className="form-check-input" name={candidate.id} type="checkbox" id="defaultCheck1" onChange={onChangeInput} />
                                                                        <Label className="form-check-label" htmlFor="defaultCheck1"></Label>
                                                                    </div>

                                                                </td>
                                                                <td>{candidate.cnp}</td>
                                                                <td>{candidate.nume}</td>
                                                                <td>{candidate.prenume}</td>
                                                                <td>{candidate.profil}</td>
                                                                {candidate.courses.length === 0 && (
                                                                    <>
                                                                        <td>-</td>
                                                                        <td>-</td>
                                                                    </>

                                                                )}
                                                                {candidate.courses.length === 1 && candidate.courses.map((course) => {
                                                                    if(course.principal === "da") {
                                                                        return (
                                                                            <td>{course.denumire}</td>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <td>-</td>
                                                                        )
                                                                    }
                                                                })}
                                                                {candidate.courses.length === 1 && candidate.courses.map((course) => {
                                                                    if(course.principal === "nu") {
                                                                        return (
                                                                            <td>{course.denumire}</td>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <td>-</td>
                                                                        )
                                                                    }
                                                                })}
                                                                {candidate.courses.length === 2 && candidate.courses.map((course) => {
                                                                    console.log("iteratie " + course)
                                                                    if(course.principal === "da") {
                                                                        return (
                                                                            <td>{course.denumire}</td>
                                                                        )
                                                                    }
                                                                    else {
                                                                        count++;
                                                                    }
                                                                    if (count === 2) {
                                                                        console.log(course.principal)
                                                                        console.log(candidate.courses.length)
                                                                    }
                                                                    if (count === candidate.courses.length) {
                                                                        count = 0;
                                                                        console.log("sunt in primul")
                                                                        return (
                                                                            <td>-</td>
                                                                        )
                                                                    }
                                                                })}
                                                                {candidate.courses.length === 2 && candidate.courses.map((course) => {
                                                                    if(course.principal === "nu") {
                                                                        return (
                                                                            <td>{course.denumire}</td>
                                                                        )
                                                                    } else {
                                                                        count2++;
                                                                    }
                                                                    if (count2 === candidate.courses.length) {
                                                                        count2 = 0;
                                                                        return (
                                                                            <td>-</td>
                                                                        )
                                                                    }
                                                                })}

                                                                <td>{candidate.courses.length}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AlocareCursuri;
