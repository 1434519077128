import React, {useState} from 'react';
import {Button, Col, Input, Label, Row} from "reactstrap";
import axiosInstance from "../../helpers/AxiosHelper";
import toastr from "toastr";

const NomenclatorMediator = () => {

    const [mediatorData, setMediatorData] = useState({
        nume: '',
        prenume: '',
        cnp: ''
    })

    async function handleMediatorSubmit(e) {
        e.preventDefault()
        try {
            await axiosInstance.post('/api/mediator/add', mediatorData,{
                headers: {"Content-Type": "application/json"}
            }).then(() => toastr.success(`Mediatorul a fost adaugat!`, ""))
                .catch(() => toastr.error(`Mediatorul nu a fost adaugat!`, ""))

        } catch(error) {
            console.log(error)
        }
        setMediatorData({
            ...mediatorData,
            nume: '',
            prenume: '',
            cnp: '',
        });
    }

    function onChangeInput(e) {
        setMediatorData({...mediatorData, [e.target.name]: e.target.value})
    }

    return (
        <form onSubmit={handleMediatorSubmit}>
            <h4 className="card-title"><b>Mediator</b></h4>
            <Row className="mb-3">
                <Label htmlFor="example-nume-input" className="col-md-4 col-form-label">Nume</Label>
                <Col md={8}>
                    <Input type="text" name="nume" value={mediatorData.nume} placeholder="Introdu Nume" id="example-nume-input" onChange={onChangeInput} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Label htmlFor="example-cui-input" className="col-md-4 col-form-label">Prenume</Label>
                <Col md={8}>
                    <Input type="text" name="prenume" value={mediatorData.prenume} placeholder="Introdu prenume" id="example-cui-input" onChange={onChangeInput} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Label htmlFor="example-cui-input" className="col-md-4 col-form-label">CNP</Label>
                <Col md={8}>
                    <Input type="text" name="cnp" value={mediatorData.cnp} placeholder="Introdu CNP" id="example-cui-input" onChange={onChangeInput} />
                </Col>
            </Row>
            <Row>
                <Col xs={6}></Col>
                <Col xs={6} align="center">
                    <Button
                        color="dark"
                        className="waves-effect waves-light me-1"
                    >
                        Adauga Mediator
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default NomenclatorMediator;
