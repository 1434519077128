import React, {useEffect, useState} from 'react';
import CandidatiBar from "./CandidatiBar";
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axiosInstance from "../../helpers/AxiosHelper";
import {useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";
import {Link} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import SecondaryNavbar from "../../components/HorizontalLayout/SecondaryNavbar";
import toastr from "toastr";
import FileDownload from "js-file-download";

const CursuriCandidati = () => {
    const [atomId, setAtomId] = useAtom(idAtom);
    const [candidate, setCandidate] = useState(null);
    const [providers, setProviders] = useState([]);
    const [locations, setLocations] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [candidateIdToEdit, setCandidateIdToEdit] = useState(null);
    const [isProviderDisabled, setIsProviderDisabled] = useState(true);
    const [isLocationDisabled, setIsLocationDisabled] = useState(true);
    const [isSessionDisabled, setIsSessionDisabled] = useState(true);
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionsByLocation, setSessionsByLocation] = useState([]);
    const [sessionIdToDelete, setSessionIdToDelete] = useState(null);
    const [courseIdToDelete, setCourseIdToDelete] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');
    const [formData, setFormData] = useState({
        curs: '',
        furnizor: '',
        locatie: '',
        sesiune: '',
    });
    const [degreeFileIdToDelete, setDegreeFileIdToDelete] = useState(null);

    const breadcrumbItems = [
        { title : "Forms", link : "#" },
        { title : "Form Validation", link : "#" },
    ];

    async function getCandidate() {
        await axiosInstance.get('/api/candidate/id', {
            params: {
                id: atomId
            },
        }).then(r => {
            setCandidate(r.data);
            console.log(r.data)
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getCandidate()
        getCourses()
        // getDegreeByCandidateId();
    },[]);

    function onChangeInput(e) {
        setFormData({...formData, [e.target.name]: e.target.value});
        if (e.target.name === "curs") {
            getProviders(e.target.value);
        }
        else if (e.target.name === "furnizor") {
            getSessions(e.target.value)
            setIsLocationDisabled(false);
        }
        else if (e.target.name === "locatie") {
            const filteredSessions = sessions.filter(session => session.locatie === e.target.value);
            setSessionsByLocation(filteredSessions)
            setIsSessionDisabled(false);
        }
    }

    function handleSubmit() {

    }

    async function getSessions(providerId) {
        await axiosInstance.get('/api/session/course/id', {
            params: {
                cursId: formData.curs,
                furnizorId: providerId
            }
        })
            .then(r => {
                const response= r.data
                setSessions(response);
                setLocations([...new Set(response.map(session => session.locatie))])
                // console.log(r.data)
                // setLoading(false);
            })

    }

    async function getCourses() {
        await axiosInstance.get('/api/course/candidate-profile/id', {
            params: {
                id: atomId
            }
        })
            .then(r => {
                const response = r.data;
                setCourses(response);
            })
    }


    async function getProviders(courseId) {
        await axiosInstance.get('/api/provider/course/id', {
            params: {
                id: courseId
            }
        })
            .then(r => {
                setProviders(r.data);
                // console.log(r.data)
                // setLoading(false);
            })
        setIsProviderDisabled(false);
    }

    if(isLoading) {
        return (<div></div>)
    }

    async function handleAssignation() {
        try {
            const response = await axiosInstance.post('/api/course/assign-candidates', [atomId],{
                headers: {"Content-Type": "application/json"},
                params: {
                    courseId: formData.curs,
                    sessionId: formData.sesiune
                }
            })
            console.log(response)
            getCandidate();
        } catch(error) {
            console.log(error)
        }
    }

    function getDateFormat(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('ro-RO', {year: 'numeric', month: '2-digit', day: '2-digit'});
    }

    function handleEdit(e, courseId, sessionId) {
        setCandidateIdToEdit(atomId)
        setFormData({
            ...formData,
            curs: '',
            furnizor: '',
            locatie: '',
            sesiune: '',
        });
    }

    if (successConfirm) {
        if (degreeFileIdToDelete) {
            try {
                axiosInstance.delete('/api/degreefile/delete/id', {
                    params: {
                        id: atomId
                    },
                }).then(res => {
                    if (!(res.status === 200)) {
                        throw new Error(`Error! status: ${res.status}`);
                    }
                    const response = res.data;
                    console.log(response)
                    getCandidate();
                })
            } catch(error) {
                console.log(error)
            }
        } else {
            try {
                axiosInstance.delete('/api/candidate/unassign', {
                    params: {
                        candidateId: atomId,
                        courseId: courseIdToDelete,
                        sessionId: sessionIdToDelete
                    },
                }).then(res => {
                    if (!(res.status === 200)) {
                        throw new Error(`Error! status: ${res.status}`);
                    }
                    const response = res.data;
                    console.log(response)
                    getCourses();
                    getCandidate();
                })
            } catch(error) {
                console.log(error)
            }
        }


        setSuccessConfirm(false)
    }

    function handleDelete(e, courseId, sessionId) {
        setIsAlert(true);
        setCourseIdToDelete(courseId);
        setSessionIdToDelete(sessionId);
        // try {
        //     axiosInstance.delete('/api/candidate/unassign', {
        //         params: {
        //             candidateId: atomId,
        //             courseId: courseId,
        //             sessionId: sessionId
        //         },
        //     }).then(res => {
        //         if (!(res.status === 200)) {
        //             throw new Error(`Error! status: ${res.status}`);
        //         }
        //         const response = res.data;
        //         console.log(response)
        //         getCourses();
        //         getCandidate();
        //     })
        // } catch(error) {
        //     console.log(error)
        // }
    }

    async function uploadDegreeFile(e, file) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", atomId);
        try {
            await axiosInstance.post(`/api/degreefile/add`, formData, {
                headers: {"Content-Type": "multipart/form-data"},
            }).then(res => {
                if (!(res.status === 200 || res.status === 201 )) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                toastr.success(`Documentul a fost incarcat!`, "")
            }).catch(() => toastr.error(`Documentul nu a fost incarcat!`, ""))
        } catch (error) {
            console.log(error)
        }
        getCandidate();
    }
    function deleteScanFile(e, file) {
        e.preventDefault();
        setIsAlert(true);
        setDegreeFileIdToDelete(file.id);
    }

    function downloadDegreeFile(e, id) {
        e.preventDefault();
        axiosInstance.get(`/api/degreefile/download/${atomId}`, {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            console.log(response)
            FileDownload(response,  "Certificat_de_calificare_" + candidate.nume + "_" + candidate.prenume, response.type)
        })
    }

    function deleteDegreeFile(e, id) {
        e.preventDefault();
        setIsAlert(true);
        setDegreeFileIdToDelete(true);
    }

    return (
        <React.Fragment>
            {/*<CandidatiBar page="Cursuri Candidati"/>*/}
            <SecondaryNavbar />
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }
                    }
                    onCancel={() => {
                        setIsAlert(false);
                    }
                    }
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>

                    <Breadcrumbs title={candidate.nume + " " + candidate.prenume} breadcrumbItems={breadcrumbItems} />
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><b>Sesiuni</b></h4>

                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-1 col-form-label">Selectati curs</Label>
                                            <Col md={4}>
                                                <select className="form-select" name="curs" onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza curs</option>
                                                    {courses.map((item) => <option key={item.id} value={item.id}>{item.denumire}</option>)}
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        {isProviderDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Furnizor</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="furnizor" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza furnizor</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isProviderDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Furnizor</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="furnizor" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Furnizor</option>
                                                        {providers.map((item) => <option value={item.id}>{item.nume}</option>)}
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                        {isLocationDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Locatie</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="locatie" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza locatie</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isLocationDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Locatie</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="locatie" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza locatie</option>
                                                        {locations.map((item) => <option value={item}>{item}</option>)}

                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                        {isSessionDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Sesiune</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="sesiune" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza sesiune</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isSessionDisabled && (
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Sesiune</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="sesiune" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza sesiune</option>
                                                        {sessionsByLocation.map((item) => <option value={item.id}>Sesiune( {getDateFormat(item.dataStart)} ... {getDateFormat(item.dataFinal)} )</option>)}
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                        <Row>
                                            <Col xs={9}></Col>
                                            <Col xs={3} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                    onClick={handleAssignation}
                                                >
                                                    Aloca
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-rep-plugin">
                                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                <Table id="tech-companies-1" striped bordered responsive>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="dimenisune-col">Curs</th>
                                                                        <th className="dimenisune-col">Furnizor</th>
                                                                        <th className="dimenisune-col">Locatie</th>
                                                                        <th className="dimenisune-col">Sesiune(perioada)</th>
                                                                        <th className="dimenisune-col">Rezultat</th>
                                                                        <th className="dimenisune-col">Actiune</th>
                                                                        <th className="dimenisune-col">Diploma</th>

                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {candidate.courses.map((course) => {
                                                                        return (
                                                                            <tr key={course.id}>
                                                                                <td>{course.denumire}</td>

                                                                                {candidate.sessions.map((session) => {
                                                                                    if (course.id === session.coursePrv.id) {
                                                                                        return (
                                                                                            <>
                                                                                                <td>{session.provider.nume}</td>
                                                                                                <td>{session.locatie}</td>
                                                                                                <td>{getDateFormat(session.dataStart)} ... {getDateFormat(session.dataFinal)}</td>
                                                                                                <td></td>

                                                                                                <td><Link onClick={e => handleEdit(e, course.id, session.id)} style={{cursor: "pointer"}}>Edit</Link> / <Link onClick={e => handleDelete(e, course.id, session.id)} style={{cursor: "pointer"}}>Delete</Link></td>

                                                                                                {!candidate.isDegree && (
                                                                                                    <td>
                                                                                                        <label htmlFor="filePicker1"  style={{cursor:"pointer"}}>
                                                                                                            Upload
                                                                                                            <input id="filePicker1" type="file" name="file1" onChange={e => uploadDegreeFile(e, candidate.id)} style={{display: "none"}}></input>
                                                                                                        </label>
                                                                                                    </td>
                                                                                                )}
                                                                                                {candidate.isDegree && (
                                                                                                    <td>
                                                                                                        <Link onClick={e => downloadDegreeFile(e, candidate.id)} style={{cursor: "pointer"}}>Download</Link>
                                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                        <i className="fas fa-trash-alt" onClick={e => deleteDegreeFile(e, candidate.id)} style={{cursor: "pointer"}}></i>
                                                                                                    </td>

                                                                                                )}
                                                                                            </>

                                                                                        )
                                                                                    }
                                                                                })}

                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                    </form>
                    <br/>
                    <br/>
                </Container>
            </div>
        </React.Fragment>
    );

};

export default CursuriCandidati;
