import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Input, Label, Row, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import axiosInstance from "../../helpers/AxiosHelper";
import CursuriBar from "./CursuriBar";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import {useAtom} from "jotai";
import {idAtom} from "./TableComponent";
import SecondaryNavbar from "../../components/HorizontalLayout/SecondaryNavbar";

const Cursuri = () => {
    const [atomId, setAtomId] = useAtom(idAtom);
    const [courses, setCourses] = useState([]);
    const [courseIdToEdit, setCourseIdToEdit] = useState(null);
    const [courseIdToDelete, setCourseIdToDelete] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');
    const [formData, setFormData] = useState({
        denumire: '',
        descriere: '',
        principal: 'da',
        oreTeorie: '',
        orePractica: '',
        cursA: 'nu',
        cursB: 'da',
        cursC: 'da',
        cursD: 'da',
    });

    const breadcrumbItems = [
        { title : "Tables", link : "#" },
        { title : "Responsive Table", link : "#" },
    ];

    useEffect(() => {
        if(atomId !== null) setAtomId(null);
        getCourses();
    }, []);

    function getCourses() {
        axiosInstance.get('/api/course/all').then((response) => {
            setCourses(response.data);
            console.log(response.data);
        })
    }

    function onChangeInput(e) {
        console.log(e.target.type)
        if (e.target.type === "checkbox") {
            setFormData({...formData, [e.target.name]: (e.target.checked ? "da" : "nu")})
        }
        else if (e.target.type === "text") {
            setFormData({...formData, [e.target.name]: e.target.value})
        }
        else if (e.target.type === "select-one") {
            console.log(e.target.name)
            console.log(e.target.value)
            setFormData({...formData, [e.target.name]: e.target.value})
        }
        else if (e.target.type === "date") {
            console.log("sunt in date")
            setFormData({...formData, [e.target.name]: e.target.value})
        }
        else {
            console.log("sunt in else")
            setFormData({...formData, [e.target.name]: e.target.value})
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            if(courseIdToEdit !== null) {
                await axiosInstance.put('/api/course/update', formData,{
                    headers: {"Content-Type": "application/json"},
                    params: {
                        courseId: courseIdToEdit
                    }
                }).then(() => toastr.success(`Cursul a fost actualizat!`, ""))
                    .catch(() => toastr.error(`Cursul nu a fost actualizat!`, ""))

            } else {
                await axiosInstance.post('/api/course/add', formData,{
                    headers: {"Content-Type": "application/json"}
                })
            }

        } catch(error) {
            console.log(error)
        }
        setFormData({
            ...formData,
            denumire: '',
            descriere: '',
            principal: 'nu',
            oreTeorie: '',
            orePractica: '',
            cursA: 'nu',
            cursB: 'nu',
            cursC: 'nu',
            cursD: 'nu',
        });
        setCourseIdToEdit(null)
        const response = await axiosInstance.get('/api/course/all');
        console.log(response)
        setCourses(response.data)
    }

    function handleEdit(e, courseId) {
        const course = courses.find((course) => course.id === courseId);
        setCourseIdToEdit(courseId)
        setFormData({
            ...formData,
            denumire: course.denumire,
            descriere: course.descriere,
            principal: course.principal,
            oreTeorie: course.oreTeorie,
            orePractica: course.orePractica,
            cursA: course.cursA,
            cursB: course.cursB,
            cursC: course.cursC,
            cursD: course.cursD
        });
    }

    if (successConfirm) {
        console.log("intra in stergere")
        try {
            axiosInstance.delete('/api/course/delete/id', {
                params: {
                    id: courseIdToDelete
                },
            }).then(res => {
                if (!(res.status === 200)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                const response = res.data;
                console.log(response)
                getCourses();
            })
        } catch(error) {
            console.log(error)
        }
        setSuccessConfirm(false)
    }

    function handleDelete(e, courseId) {
        console.log("aiciiiii" + courseId)
        setIsAlert(true);
        setCourseIdToDelete(courseId);
        // try {
        //     axiosInstance.delete('/api/course/delete/id', {
        //         params: {
        //             id: courseId
        //         },
        //     }).then(res => {
        //         if (!(res.status === 200)) {
        //             throw new Error(`Error! status: ${res.status}`);
        //         }
        //         const response = res.data;
        //         console.log(response)
        //         getCourses();
        //     })
        // } catch(error) {
        //     console.log(error)
        // }
    }

    return (
        <React.Fragment>
            <SecondaryNavbar />
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }
                    }
                    onCancel={() => {
                        setIsAlert(false);
                    }
                    }
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>
                    {/*<CursuriBar page="Cursuri"/>*/}

                    {/*<Breadcrumbs title="Adaugare / Editare cursuri" breadcrumbItems={breadcrumbItems} />*/}

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Breadcrumbs title="Adaugare Editare curs" breadcrumbItems={breadcrumbItems} />

                                    {/*<Link to="/formular-candidati"><h4 className="card-title">+Adauga</h4></Link>*/}
                                    {/*<p className="card-title-desc">This is an experimental awesome solution for responsive tables with complex data.</p>*/}
                                    <form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={4}>
                                                <Row>
                                                    <Label htmlFor="example-denumire-input" className="col-md-3 col-form-label"><b>Denumire curs*</b></Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="denumire" placeholder="Introdu denumire curs" id="example-denumire-input" onChange={onChangeInput} value={formData.denumire}/>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label className="col-md-3 col-form-label"><b>Curs principal</b></Label>
                                                    <Col md={2}>
                                                        <div className="form-check mb-3">
                                                            <Input className="form-check-input" name="principal" type="checkbox" id="defaultCheck5" onChange={onChangeInput} checked={formData.principal === "da"} />
                                                            <Label className="form-check-label" htmlFor="defaultCheck5">
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={2}></Col>
                                            <Col xs={4}>
                                                <Row>
                                                    <Label htmlFor="example-descriere-input" className="col-md-3 col-form-label"><b>Descriere*</b></Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="descriere" placeholder="Introdu descriere curs" id="example-descriere-input" onChange={onChangeInput} value={formData.descriere}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={1}>
                                                <Label className="col-md-3 col-form-label"><b>A</b></Label>
                                                    <div className="form-check mb-3">
                                                        <Input className="form-check-input" name="cursA" type="checkbox" checked={formData.cursA === "da"} id="defaultCheck6" onChange={onChangeInput}  />
                                                        <Label className="form-check-label" htmlFor="defaultCheck6">
                                                        </Label>
                                                    </div>
                                            </Col>
                                            <Col xs={1}>
                                                <Label className="col-md-3 col-form-label"><b>B</b></Label>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="cursB" type="checkbox" checked={formData.cursB === "da"} id="defaultCheck7" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck7">
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={1}>
                                                <Label className="col-md-3 col-form-label"><b>C</b></Label>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="cursC" type="checkbox" checked={formData.cursC === "da"} id="defaultCheck8" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck8">
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={1}>
                                                <Label className="col-md-3 col-form-label"><b>D</b></Label>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="cursD" type="checkbox" checked={formData.cursD === "da"} id="defaultCheck9" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck9">
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col xs={3}></Col>
                                            <Col xs={2}>
                                                <Label htmlFor="example-oreteorie-input" className="col-md-3 col-form-label"><b>Teorie</b></Label>
                                                <Col md={9}>
                                                    <Input type="text" name="oreTeorie" placeholder="Introdu teorie" id="example-oreteorie-input" onChange={onChangeInput} value={formData.oreTeorie}/>
                                                </Col>
                                            </Col>
                                            <Col xs={2}>
                                                <Label htmlFor="example-orepractica-input" className="col-md-3 col-form-label">Practica</Label>
                                                <Col md={9}>
                                                    <Input type="text" name="orePractica" placeholder="Introdu practica" id="example-orepractica-input" onChange={onChangeInput} value={formData.orePractica}/>
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={9}></Col>
                                            <Col xs={1} align="center">
                                                <Button
                                                    color="dark"
                                                    className="waves-effect waves-light me-1"
                                                >
                                                    Salveaza
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                            <Table id="tech-companies-1" striped bordered responsive>
                                                <thead>
                                                <tr>
                                                    <th className="dimenisune-col">Curs</th>
                                                    <th className="dimenisune-col">Descriere curs</th>
                                                    <th className="dimenisune-col">Principal</th>
                                                    <th className="dimenisune-col">Ore teorie</th>
                                                    <th className="dimenisune-col">Ore practica</th>
                                                    <th className="dimenisune-col">A</th>
                                                    <th className="dimenisune-col">B</th>
                                                    <th className="dimenisune-col">C</th>
                                                    <th className="dimenisune-col">D</th>
                                                    <th className="dimenisune-col">Actiune</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>

                                                </tr>
                                                {courses.map((course) => {
                                                    return (
                                                        <tr key={course.id}>
                                                            <td>{course.denumire}</td>
                                                            <td>{course.descriere.length > 30 ? `${course.descriere.substring(0, 30)}...` : course.descriere}</td>
                                                            <td>{course.principal}</td>
                                                            <td>{course.oreTeorie}</td>
                                                            <td>{course.orePractica}</td>
                                                            <td>{course.cursA}</td>
                                                            <td>{course.cursB}</td>
                                                            <td>{course.cursC}</td>
                                                            <td>{course.cursD}</td>
                                                            {course.empty ? (
                                                                <td><Link onClick={e => handleEdit(e, course.id)} style={{cursor: "pointer"}}>Edit</Link> / <Link onClick={e => handleDelete(e, course.id)} style={{cursor: "pointer"}}>Delete</Link></td>
                                                            ) : (
                                                                <td>Nu este permisa</td>
                                                            )}
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Cursuri;
