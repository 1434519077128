import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import axiosInstance from "../../helpers/AxiosHelper";
import NomenclatorFurnizor from "./NomenclatorFurnizor";
import NomenclatorMediator from "./NomenclatorMediator";
import NomenclatorObservator from "./NomenclatorObservator";
import SecondaryNavbar from "../../components/HorizontalLayout/SecondaryNavbar";
import {useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";

const Nomenclatoare = () => {
    const [atomId, setAtomId] = useAtom(idAtom);
    useEffect(() => {
        if(atomId !== null) setAtomId(null);
    }, [])

    return (
        <React.Fragment>
            <SecondaryNavbar />
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>
                    <Row>
                        <Col xs={4}>
                            <Card>
                                <CardBody>
                                    <NomenclatorFurnizor/>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={4}>
                            <Card>
                                <CardBody>
                                    <NomenclatorMediator/>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={4}>
                            <Card>
                                <CardBody>
                                    <NomenclatorObservator/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Nomenclatoare;
