import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Table} from "reactstrap";
import CursuriBar from "./CursuriBar";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axiosInstance from "../../helpers/AxiosHelper";
import SecondaryNavbar from "../../components/HorizontalLayout/SecondaryNavbar";

const RezultateCursuri = () => {

    const [courses, setCourses] = useState([]);
    const [assignedCandidates, setAssignedCandidates] = useState([]);
    const [assignedCandidatesByProvider, setAssignedCandidatesByProvider] = useState([]);
    // const [sessionsIds, setSessionIds] = useState([]);
    const [graduatedCandidates, setGraduatedCandidates] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [providers, setProviders] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isProviderDisabled, setIsProviderDisabled] = useState(true);
    const [isLocationDisabled, setIsLocationDisabled] = useState(true);
    const [isSessionDisabled, setIsSessionDisabled] = useState(true);
    const [sessionsByLocation, setSessionsByLocation] = useState([]);

    const [formData, setFormData] = useState({
        curs: '',
        furnizor: '',
        locatie: '',
        sesiune: '',
    });

    const breadcrumbItems = [
        { title : "Tables", link : "#" },
        { title : "Responsive Table", link : "#" },
    ];

    useEffect(() => {
        getCourses();
    },[]);

    async function getCourses() {
        await axiosInstance.get('/api/course/all')
            .then(r => {
                setCourses(r.data);
                // console.log(r.data)
                // setLoading(false);
            })
    }

    function handleSubmit() {

    }

    async function getAssignedCandidates(courseId) {
        await axiosInstance.get('/api/course/id', {
            params: {
                id: courseId
            }
        })
            .then(r => {
                setAssignedCandidates(r.data);
                // console.log(r.data)
                // setLoading(false);
            })
    }

    async function getProviders(courseId) {
        await axiosInstance.get('/api/provider/course/id', {
            params: {
                id: courseId
            }
        })
            .then(r => {
                setProviders(r.data);
                // console.log(r.data)
                // setLoading(false);
            })
        setIsProviderDisabled(false);
    }

    async function getSessions(providerId) {
        await axiosInstance.get('/api/session/course/id', {
            params: {
                cursId: formData.curs,
                furnizorId: providerId
            }
        })
            .then(r => {
                const response= r.data
                setSessions(response);
                setLocations([...new Set(response.map(session => session.locatie))])
                // console.log(r.data)
                // setLoading(false);
            })

    }

    async function getAssignedCandidatesByProvider(providerId) {
        await axiosInstance.get('/api/provider/id/course/id', {
            params: {
                cursId: formData.curs,
                furnizorId: providerId
            }
        })
            .then(r => {
                setAssignedCandidates(r.data);
                // console.log(r.data)
                // setLoading(false);
            })

    }

    async function getAssignedCandidatesByLocation(sessionsIds) {
        await axiosInstance.post('/api/session/id/candidates', sessionsIds)
            .then(r => {
                setAssignedCandidates(r.data);
                // console.log(r.data)
                // setLoading(false);
            })
    }

    function onChangeInput(e) {
        if (e.target.type === "checkbox") {
            if (e.target.checked === true) {
                setGraduatedCandidates(graduatedCandidates => [...graduatedCandidates, e.target.name])
            } else {
                const foundCandidateId = graduatedCandidates.find(candidate => candidate === e.target.name);
                if (foundCandidateId !== undefined) {
                    setGraduatedCandidates((graduatedCandidates) => graduatedCandidates.filter(candidate => candidate !== e.target.name));
                }
            }

        }
        if (e.target.name === "curs") {
            setFormData({...formData, [e.target.name]: e.target.value});
            getAssignedCandidates(e.target.value);
            getProviders(e.target.value)
        }
        else if (e.target.name === "furnizor") {
            getSessions(e.target.value)
            getAssignedCandidatesByProvider(e.target.value)
            // assignedCandidates.forEach((candidate) => {
            //     const filteredSessions = candidate.sessions.filter(session => session.provider.id.toString() === e.target.value);
            //     if (filteredSessions.length > 0) {
            //         setAssignedCandidatesByProvider(([...assignedCandidatesByProvider, candidate]))
            //     }
            // })

            setIsLocationDisabled(false);
        }
        else if (e.target.name === "locatie") {
            // getSessions(formData.furnizor)

            const filteredSessions = sessions.filter(session => session.locatie === e.target.value);
            const sessionsIds = [];
            filteredSessions.forEach(session => sessionsIds.push(session.id));
            getAssignedCandidatesByLocation(sessionsIds)
            // console.log(sessionsIds)
            // filteredSessions.forEach(session => [...sessionsIds, session.id]);
            setSessionsByLocation(filteredSessions)
            setIsSessionDisabled(false);

        }
        else if (e.target.name === "sesiune") {
            // getSessions(formData.furnizor)
            // const filteredSessions = sessions.filter(session => session.locatie === e.target.value);
            // setSessionsByLocation(filteredSessions)
            // setIsSessionDisabled(false);
            setFormData({...formData, [e.target.name]: e.target.value});
        }
    }

    async function handleGraduation(e) {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/api/course/graduate-candidates', graduatedCandidates, {
                headers: {"Content-Type": "application/json"},
                params: {
                    id: formData.curs,
                }
            })
            console.log(response)

        } catch (error) {
            console.log(error)
        }
        setIsSessionDisabled(true)
        setIsLocationDisabled(true)
        setIsProviderDisabled(true)
        setAssignedCandidates([])
        setFormData({
            curs: '',
            furnizor: '',
            locatie: '',
            sesiune: '',
        });
        getCourses()
    }

    function verifyCourseCategory() {
        const courseById = courses.find(course => course.id.toString() === formData.curs);
        if (courseById !== undefined) {
            return courseById.principal;
        }
    }
    function getDateFormat(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('ro-RO', {year: 'numeric', month: '2-digit', day: '2-digit'});
    }

    return (
        <React.Fragment>
            <SecondaryNavbar/>
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>
                    {/*<CursuriBar page="Rezultate Cursuri"/>*/}
                    {/*<Breadcrumbs title="Adaugare / Editare cursuri" breadcrumbItems={breadcrumbItems} />*/}

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Breadcrumbs title="Sesiune" breadcrumbItems={breadcrumbItems} />

                                    {/*<Link to="/formular-candidati"><h4 className="card-title">+Adauga</h4></Link>*/}
                                    {/*<p className="card-title-desc">This is an experimental awesome solution for responsive tables with complex data.</p>*/}
                                    <form onSubmit={handleSubmit}>
                                        <FormGroup row className="mb-2">
                                            <Label className="col-md-2 col-form-label">Selectie Curs*</Label>
                                            <Col md={4}>
                                                <select className="form-select" name="curs" value={formData.curs} onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza curs</option>
                                                    {courses.map((item) => <option key={item.id} value={item.id}>{item.denumire}</option>)}
                                                </select>
                                            </Col>
                                        </FormGroup>

                                        {isProviderDisabled && (
                                            <FormGroup row className="mb-2">
                                                <Label className="col-md-2 col-form-label">Selectie Furnizor</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="furnizor" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza furnizor</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isProviderDisabled && (
                                            <FormGroup row className="mb-2">
                                                <Label className="col-md-2 col-form-label">Selectie Furnizor</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="furnizor" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza furnizor</option>
                                                        {providers.map((item) => <option value={item.id}>{item.nume}</option>)}

                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                        {isLocationDisabled && (
                                            <FormGroup row className="mb-2">
                                                <Label className="col-md-2 col-form-label">Selectie Locatie</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="locatie" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza locatie</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isLocationDisabled && (
                                            <FormGroup row className="mb-2">
                                                <Label className="col-md-2 col-form-label">Selectie Locatie</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="locatie" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza locatie</option>
                                                        {locations.map((item) => <option value={item}>{item}</option>)}
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                        {isSessionDisabled && (
                                            <FormGroup row className="mb-2">
                                                <Label className="col-md-2 col-form-label">Selectie Sesiune*</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="sesiune" onChange={onChangeInput} disabled>
                                                        <option value="" disabled selected hidden>Selecteaza sesiune</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {!isSessionDisabled && (
                                            <FormGroup row className="mb-2">
                                                <Label className="col-md-2 col-form-label">Selectie Sesiune*</Label>
                                                <Col md={4}>
                                                    <select className="form-select" name="sesiune" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza sesiune</option>
                                                        {sessionsByLocation.map((item) => <option value={item.id}>Sesiune( {getDateFormat(item.dataStart)} ... {getDateFormat(item.dataFinal)} )</option>)}
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        )}

                                    </form>

                                    <Row className="mb-3">

                                        <Col xs={9}></Col>
                                        <Col xs={2} align="center">
                                            <Button
                                                color="dark"
                                                className="waves-effect waves-light me-1"
                                                onClick={handleGraduation}
                                            >
                                                Marcheaza ca absolvit
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={1}>Selectati: {graduatedCandidates.length}</Col>
                                    </Row>
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                            <Table id="tech-companies-1" striped bordered responsive>
                                                <thead>
                                                <tr>
                                                    <th className="dimenisune-col2">Selecteaza</th>
                                                    <th className="dimenisune-col">Cnp</th>
                                                    <th className="dimenisune-col">Nume</th>
                                                    <th className="dimenisune-col">Prenume</th>
                                                    <th className="dimenisune-col">Profil</th>
                                                    <th className="dimenisune-col">Absolvit</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>

                                                </tr>
                                                {assignedCandidates.map((candidate) => {
                                                    return (
                                                        <tr key={candidate.id}>
                                                            <td>
                                                                <div className="form-check mb-3">
                                                                    <Input className="form-check-input" name={candidate.id} type="checkbox" id="defaultCheck1" onChange={onChangeInput} />
                                                                    <Label className="form-check-label" htmlFor="defaultCheck1"></Label>
                                                                </div>
                                                            </td>
                                                            <td>{candidate.cnp}</td>
                                                            <td>{candidate.nume}</td>
                                                            <td>{candidate.prenume}</td>
                                                            <td>{candidate.profil}</td>
                                                            {verifyCourseCategory() === "da" && (
                                                                <td>{candidate.isPrincipalGraduated ? "da" : "nu"}</td>
                                                            )}
                                                            {verifyCourseCategory() === "nu" && (
                                                                <td>{candidate.isSecondaryGraduated ? "da" : "nu"}</td>
                                                            )}

                                                        </tr>
                                                    )
                                                })}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RezultateCursuri;
