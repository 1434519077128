import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axiosInstance from "../../helpers/AxiosHelper";
import CandidatiBar from "./CandidatiBar";
import {useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import {useHistory, useLocation} from "react-router-dom";
import UiNotifications from "../Ui/ui-notifications";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import SecondaryNavbar from "../../components/HorizontalLayout/SecondaryNavbar";
import SweetAlert from "react-bootstrap-sweetalert";
import FileDownload from "js-file-download";

toastr.options = {
    progressBar: true,
}

const Formular = () => {
    let location = useLocation();
    let history = useHistory();
    const [atomId, setAtomId] = useAtom(idAtom);
    const [counties, setCounties] = useState([]);
    const [towns, setTowns] = useState([]);
    const [townsResedinta, setTownsResedinta] = useState([]);
    const [candidate, setCandidate] = useState(null);
    const [isAnonymousMessage, setIsAnonymousMessage] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');
    const [isExportAlert, setIsExportAlert] = useState(false);
    const [ajaxDiv, setAjaxDiv] = useState(false);
    const [successDig, setSuccessDig] = useState(false);
    const [dynamicTitleE, setDynamicTitleE] = useState('');
    const [dynamicDescriptionE, setDynamicDescriptionE] = useState('');
    const [exportDate, setExportDate] = useState('');
    const [isDoingExport, setIsDoingExport] = useState(false);
    const [maxDate, setMaxDate] = useState(null);
    const [formData, setFormData] = useState({
        cnp: '',
        nume: '',
        prenume: '',
        nationalitate: 'romana',
        serieCi: '',
        numarCi: '',
        ciEliberatDe: '',
        ciEliberatLaData: '',
        dataNastere: '',
        gen: '',
        stareCivila: '',
        numeAnterior: '',
        judet: '',
        localitate: '',
        adresa: '',
        mediu: '',
        aceeasiCuCi: '',
        judetResedinta: '',
        localitateResedinta: '',
        adresaResedinta: '',
        mediuResedinta: '',
        email: '',
        telefon: '',
        partener: '',
        tipSomer: 'Somer pe o perioada mai mare de 12 luni',
        reprezentare: '',
        numeReprezentare: '',
        stareSanatate: 'nu',
        detaliiStareSanatate: '',
        etnieRoma: 'Da',
        categorieGt: '',
        situatiePePiataFm: '',
        inscrisInEducatie: 'nu',
        inscrisInFormare: 'nu',
        nivelEducatie: '',
        persoanaDezavantajata: '',
        participantiFaraPersoaneOcupate: '',
        participantiCuCopiiInIntretinere: '',
        participantiParinteUnic: '',
        migranti: '',
        participantiOrgineStraina: '',
        minoritatiEtnieRoma: '',
        alteMinoritati: '',
        persoaneCuDizabilitati: '',
        alteCategorii: '',
        persoaneFaraAdapost: '',
        apartinComunitatiMarginalizate: '',
        dataAjofm: '',
    });
    const [isCnpValid, setIsCnpValid] = useState(true);

    async function getCounties() {
        const { data } = await axiosInstance.get('/api/siruta/counties'); //url encode in const
        setCounties(data);
    }

    async function getTowns(value, isResedinta) {
        const { data } = await axiosInstance.get('/api/siruta/towns', {
            params: {
                county: value
            }
        });
        isResedinta ? setTownsResedinta(data) : setTowns(data);
    }

    async function getCandidate() {
        const { data } = await axiosInstance.get('/api/candidate/id', {
            params: {
                id: atomId
            },
        });
        setCandidate(data);
        const filteredEntries = Object.entries(data).filter(([key]) => key in formData);
        const filteredObject = Object.fromEntries(filteredEntries);
        if (data?.dataIntrareGt !== null) {
            const dateObject = new Date(data.dataIntrareGt === "" ? null : new Date(data.dataIntrareGt));
            dateObject.setDate(dateObject.getDate() + 7);
            setMaxDate(dateObject);
        }
        await getTowns(data?.judet);
        setFormData({...formData, ...filteredObject});
    }

    useEffect(() => {
        async function fetchData() {
            await getCounties();
            if (atomId !== null) {
                await getCandidate();
            }
        }
        fetchData();
    }, [atomId]);

    const onChangeInput = (e) => {
        const { name, type, checked, value } = e.target;

        if (type === "checkbox") {
            if (name === "aceeasiCuCi" && checked) {
                setFormData({...formData,
                    judetResedinta: formData.judet,
                    localitateResedinta: formData.localitate,
                    adresaResedinta: formData.adresa,
                    mediuResedinta: formData.mediu
                });
            }
            setFormData({...formData, [name]: (checked ? "da" : "nu")});
        } else {
            setFormData({...formData, [name]: value});
        }

        if (name === "judet") getTowns(value, false);
        if (name === "judetResedinta") getTowns(value, true);
        if (name === "cnp") {
            const numbers = value.match(/\d/g);
            setIsCnpValid(value.length === 13 && numbers?.length === 13);
        }
    };

    async function handleSubmit(e) {
        e.preventDefault();

        const config = {headers: {"Content-Type": "application/json"}};
// intercep req & res
        try {
            if (location.pathname === "/formular") { //hook addCandidate (vezi si angular servicii)
                await axiosInstance.post('/api/candidate/add/anonymous', formData, config)
                setIsAnonymousMessage(true);
            } else if (atomId === null) {
                await axiosInstance.post('/api/candidate/add', formData, config);
                toastr.success(`Candidatul a fost adaugat!`, "");
            } else {
                await axiosInstance.patch('/api/candidate/update', formData, {
                    headers: {"Content-Type": "application/json"},
                    params: { id: atomId }
                });
                toastr.success(`Candidatul ${candidate.nume} ${candidate.prenume} a fost actualizat!`, "");
            }
        } catch (error) {
            console.log(error); //retry in axios vezi
            if (error.response.status === 417) toastr.error(`CNP-ul exista in baza de date! Candidatul nu poate fi adaugat!`, "");
            else if (atomId !== null) toastr.error(`Candidatul ${candidate.nume} ${candidate.prenume} nu a fost actualizat!`, "");
            else toastr.error(`Candidatul nu a fost adaugat!`, "");
        }
    }

    const handleDateChange = (event, dateType) => {
        const localDate = new Date(event);
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        if (dateType === "dataAjofm") {
            setFormData({ ...formData, dataAjofm: formattedDate });
        } else if (dateType === "exportDate") {
            setExportDate(formattedDate);
        }
        else {
            setFormData({ ...formData, ciEliberatLaData: formattedDate });
        }

    };

    async function handleDelete() {
        try {
            await axiosInstance.delete('/api/candidate/delete', {
                headers: {"Content-Type": "application/json"},
                params: {
                    candidateId: atomId
                }
            });
            toastr.success(`Candidatul a fost sters!`, "");
            history.push('/tabel-candidati');
        } catch (error) {
            console.log(error);
            toastr.error(`Candidatul nu a fost sters!`, "");
        }
    }

    if (successConfirm) {
        handleDelete();
        setSuccessConfirm(false);
    }

    function startDeleteProcess(e) {
        e.preventDefault();
        setIsAlert(true);
    }

    function handleExport(e) {
        e.preventDefault();
        setIsExportAlert(true);
    }

    async function handleGenerate() {
        try {
            const { data: response } = await axiosInstance.post(`/api/xlsm/generate`, [atomId], {
                responseType: "blob",
                params: {
                    expDate: exportDate
                }
            });
            setIsDoingExport(false);
            const fileName = `formular_participant_154951_${candidate.prenume}_${candidate.nume}.xlsm`;
            FileDownload(response, fileName, response?.type);
        } catch (error) {
            let errorMsg;
            if (error?.response) {
                const reader = new FileReader();
                reader.readAsText(error.response.data);
                reader.onload = () => {
                    errorMsg = reader.result;
                    toastr.error(errorMsg, "");
                };
            } else {
                errorMsg = "A aparut o eroare! Formularul nu poate fi completat!";
                toastr.error(errorMsg, "");
            }
            setIsDoingExport(false);
        }
    }

    if (successDig) {
        handleGenerate();
        setIsDoingExport(false);
        setSuccessDig(false);
        setExportDate('');
    }


    if (isAnonymousMessage) {
        return (
            <React.Fragment>
                <div className="page-content" style={{paddingTop: "70px"}}>
                    <Container fluid>
                        <Row className="mb-4">
                            <h1 align="center"><b>Datele dvs. vor fi verificate si va vom oferi asistenta in vederea completarii documentelor.</b></h1>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }
                    }
                    onCancel={() => {
                        setIsAlert(false);
                    }
                    }
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            {isExportAlert && (
                <SweetAlert
                    showCancel
                    title="Selecteaza data:"
                    cancelBtnBsStyle="danger"
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        setIsExportAlert(false);
                        setSuccessDig(true);
                        setDynamicTitleE("aaaaa");
                        setDynamicDescriptionE("bbbbb");
                        setIsDoingExport(true);
                    }}
                    onCancel={() => setIsExportAlert(false)}
                >
                    <div style={{position: "relative", paddingBottom: "250px"}}>
                        <DatePicker
                            name="ciEliberatLaData"
                            className="form-control"
                            selected={exportDate === '' || exportDate === null ? null : new Date(exportDate.toLocaleString())}
                            onChange={(event) => handleDateChange(event, "exportDate")}
                            dateFormat="dd-MM-yyyy"
                            autoComplete="off"
                            placeholderText="ZZ-LL-AAAA"
                            minDate={new Date(2022,6,13)}
                            maxDate={maxDate}
                        />
                        <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                            <span>
                                <i className="far fa-calendar"></i>
                            </span>
                        </div>
                    </div>
                </SweetAlert>
            )}
            {isDoingExport && (
                <SweetAlert
                    title="Se completeaza formularul!"
                    showConfirm={false}
                    // onConfirm={() => setIsDoingExport}
                >
                    <div align="center">
                        <Spinner className="me-2" color="primary" />
                    </div>
                </SweetAlert>
            )}
            {location.pathname !== "/formular" && (
                <>
                    {/*<CandidatiBar page="Formular Candidati" />*/}
                    <SecondaryNavbar />
                </>

            )}
            {location.pathname === "/formular" && (
                <div className="page-content" style={{paddingTop: "100px"}}>
                    <Container fluid>
                        <Row>
                            <h1 align="center"><b>FORMULAR INSCRIERE CANDIDAT</b></h1>
                        </Row>
                    </Container>
                </div>
            )}
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>
                    {location.pathname !== "/formular" && (
                        <Row>
                            <Col md={10}></Col>
                            <Col md={2}>
                                <h4 className="card-title" onClick={handleExport} style={{cursor: "pointer", textAlign: "right"}} align="center">Export <i className="fas fa-file-export"></i></h4>
                            </Col>
                        </Row>
                    )}
                    <form onSubmit={handleSubmit}>
                        <Row className="mb-4">
                            <Col xs={4}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><b>Date identificare</b></h4>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-cnp-input" className="col-md-3 col-form-label">CNP*</Label>
                                            <Col md={9}>
                                                <Input className={isCnpValid ? "" : "is-invalid"} type="text" name="cnp" placeholder="Introdu CNP" value={formData.cnp} id="example-cnp-input" onChange={onChangeInput} />
                                                <div className="invalid-feedback">
                                                    CNP-ul poate contine doar 13 cifre.
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-nume-input" className="col-md-3 col-form-label">Nume*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="nume" placeholder="Introdu nume" value={formData.nume} id="example-nume-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-prenume-input" className="col-md-3 col-form-label">Prenume*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="prenume" placeholder="Introdu prenume" value={formData.prenume} id="example-prenume-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Nationalitate*</Label>
                                            <Col md={9}>
                                                <select className="form-select" name="nationalitate" value={formData.nationalitate} onChange={onChangeInput}>
                                                    {/*<option value="" disabled selected hidden>Selecteaza stare civila</option>*/}
                                                    <option defaultValue="Romana">Romana</option>
                                                    <option value="Alta">Alta</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-serieci-input" className="col-md-3 col-form-label">Serie CI*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="serieCi" value={formData.serieCi} placeholder="Introdu Serie CI" id="example-serieci-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-numarci-input" className="col-md-3 col-form-label">Numar CI*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="numarCi" value={formData.numarCi} placeholder="Introdu Numar CI" id="example-numarci-input" onChange={onChangeInput}/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-cieliberat-input" className="col-md-3 col-form-label">CI eliberat de*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="ciEliberatDe" value={formData.ciEliberatDe} placeholder="Introdu eliberator CI" id="example-cieliberat-input" onChange={onChangeInput}/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-cidata-input" className="col-md-3 col-form-label">CI eliberat la data*</Label>
                                            <Col md={9}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="ciEliberatLaData"
                                                        className="form-control"
                                                        selected={formData.ciEliberatLaData === '' || formData.ciEliberatLaData === null ? null : new Date(formData.ciEliberatLaData.toLocaleString())}
                                                        onChange={(event) => handleDateChange(event, "ciEliberatLaData")}
                                                        dateFormat="dd-MM-yyyy"
                                                        autoComplete="off"
                                                        placeholderText="ZZ-LL-AAAA"
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        {formData.cnp.length < 7 && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-datanastere-input" className="col-md-3 col-form-label" style={{color: "red"}}>Data nastere</Label>
                                                <Col md={9}>
                                                    <Input type="text" value="" name="dataNastere" readonly="readonly" id="example-datanastere-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {formData.cnp.length >= 7 && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-datanastere-input" className="col-md-3 col-form-label" style={{color: "red"}}>Data nastere</Label>
                                                <Col md={9}>
                                                    <Input type="text" value={formData.cnp.charAt(5) +
                                                        formData.cnp.charAt(6) +
                                                        "-" + formData.cnp.charAt(3) +
                                                        formData.cnp.charAt(4) +
                                                        "-" + (formData.cnp.charAt(0) === '1' || formData.cnp.charAt(0) === '2' ? "19" : "20") +
                                                        formData.cnp.charAt(1) +
                                                        formData.cnp.charAt(2)} readonly="readonly" name="dataNastere" id="example-datanastere-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {(formData.cnp.charAt(0) === '1' || formData.cnp.charAt(0) === '5') && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-gen-input" className="col-md-3 col-form-label" style={{color: "red"}}>Gen</Label>
                                                <Col md={9}>
                                                    <Input type="text" value="Masculin" readonly="readonly" id="example-gen-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {(formData.cnp.charAt(0) === '2' || formData.cnp.charAt(0) === '6')  && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-gen-input" className="col-md-3 col-form-label" style={{color: "red"}}>Gen</Label>
                                                <Col md={9}>
                                                    <Input type="text" value="Feminin" readonly="readonly" id="example-gen-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        {(formData.cnp.charAt(0) !== '1' &&
                                            formData.cnp.charAt(0) !== '2' &&
                                            formData.cnp.charAt(0) !== '5' &&
                                            formData.cnp.charAt(0) !== '6') && (
                                            <Row className="mb-3">
                                                <Label htmlFor="example-gen-input" className="col-md-3 col-form-label" style={{color: "red"}}>Gen</Label>
                                                <Col md={9}>
                                                    <Input type="text" value="" readonly="readonly" id="example-gen-input" onChange={onChangeInput}/>
                                                </Col>
                                            </Row>
                                        )}
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Stare civila*</Label>
                                            <Col md={9}>
                                                <select className="form-select" value={formData.stareCivila} name="stareCivila" onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza stare civila</option>
                                                    <option value="casatorit">Casatorit</option>
                                                    <option value="necasatorit">Necasatorit</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-numeanterior-input" className="col-md-3 col-form-label">Nume anterior</Label>
                                            <Col md={9}>
                                                <Input type="text" name="numeAnterior" value={formData.numeAnterior} placeholder="Introdu nume anterior" id="example-numeanterior-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={4}>
                                <Card>
                                    <CardBody >
                                        <h4 className="card-title"><b>Adresa CI</b></h4>

                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Judet*</Label>
                                            <Col md={9}>
                                                <select className="form-select" value={formData.judet} name="judet" onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza judet</option>
                                                    {counties.map((county) => <option value={county}>{county}</option>)}
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Localitate*</Label>
                                            <Col md={9}>
                                                <select className="form-select" value={formData.localitate} name="localitate" onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza localitate</option>
                                                    {towns.map((town) => <option value={town}>{town}</option>)}
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-adresa-input" className="col-md-3 col-form-label">Adresa(str, nr, bl, et)*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="adresa" value={formData.adresa} placeholder="Introdu adresa" id="example-adresa-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-0">
                                            <Label className="col-md-3 col-form-label">Mediu*</Label>
                                            <Col md={9}>
                                                <select className="form-select" value={formData.mediu} name="mediu" onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza mediul</option>
                                                    <option value="Urban">Urban</option>
                                                    <option value="Rural">Rural</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <br/>
                                        <h4 className="card-title"><b>Adresa resedinta</b></h4>
                                        <div className="form-check mb-3">
                                            <Input className="form-check-input" checked={formData.aceeasiCuCi === "da"} name="aceeasiCuCi" type="checkbox" id="defaultCheck1" onChange={onChangeInput} />
                                            <Label className="form-check-label" htmlFor="defaultCheck1">
                                                Aceeasi cu CI
                                            </Label>
                                        </div>
                                        {formData.aceeasiCuCi === "da" && (
                                            <>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-judetresedinta-input" className="col-md-3 col-form-label">Judet</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="judetResedinta" value={formData.judet} readonly="readonly" id="example-judetresedinta-input" onChange={onChangeInput}/>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-localitateresedinta-input" className="col-md-3 col-form-label">Localitate</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="localitateResedinta" value={formData.localitate} readonly="readonly" id="example-localitateresedinta-input" onChange={onChangeInput}/>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-adresaresedinta-input" className="col-md-3 col-form-label">Adresa</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="adresaResedinta" value={formData.adresa} readonly="readonly" id="example-adresaresedinta-input" onChange={onChangeInput}/>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-mediuresedinta-input" className="col-md-3 col-form-label">Mediu</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="mediuResedinta" value={formData.mediu} readonly="readonly" id="example-mediuresedinta-input" onChange={onChangeInput}/>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {formData.aceeasiCuCi !== "da" && (
                                            <>
                                                <FormGroup row className="mb-3">
                                                    <Label className="col-md-3 col-form-label">Judet</Label>
                                                    <Col md={9}>
                                                        <select className="form-select" value={formData.judetResedinta} name="judetResedinta" onChange={onChangeInput} >
                                                            <option value="" disabled selected hidden>Selecteaza judet resedinta</option>
                                                            {counties.map((item) => <option value={item}>{item}</option>)}
                                                        </select>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row className="mb-3">
                                                    <Label className="col-md-3 col-form-label">Localitate</Label>
                                                    <Col md={9}>
                                                        <select className="form-select" value={formData.localitateResedinta} name="localitateResedinta" onChange={onChangeInput}>
                                                            <option value="" disabled selected hidden>Selecteaza localitate resedinta</option>
                                                            {townsResedinta.map((item) => <option value={item}>{item}</option>)}
                                                        </select>
                                                    </Col>
                                                </FormGroup>
                                                <Row className="mb-3">
                                                    <Label htmlFor="example-adresaresedinta-input" className="col-md-3 col-form-label">Adresa</Label>
                                                    <Col md={9}>
                                                        <Input type="text" name="adresaResedinta" value={formData.adresaResedinta} placeholder="Introdu adresa resedinta" id="example-adresaresedinta-input" onChange={onChangeInput} />
                                                    </Col>
                                                </Row>
                                                <FormGroup row className="mb-0">
                                                    <Label className="col-md-3 col-form-label">Mediu</Label>
                                                    <Col md={9}>
                                                        <select className="form-select" name="mediuResedinta" value={formData.mediuResedinta} onChange={onChangeInput}>
                                                            <option value=""  disabled selected hidden>Selecteaza mediu resedinta</option>
                                                            <option value="Urban">Urban</option>
                                                            <option value="Rural">Rural</option>
                                                        </select>
                                                    </Col>
                                                </FormGroup>
                                            </>
                                        )}

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={4}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><b>Date contact</b></h4>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-email-input" className="col-md-3 col-form-label">Email*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="email" value={formData.email} placeholder="Introdu Email" id="example-email-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-telefon-input" className="col-md-3 col-form-label">Telefon*</Label>
                                            <Col md={9}>
                                                <Input type="text" name="telefon" value={formData.telefon === "" ? "+40" : formData.telefon} placeholder="Introdu Telefon" id="example-telefon-input" onChange={onChangeInput} />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Partener*</Label>
                                            <Col md={9}>
                                                <select className="form-select" name="partener" value={formData.partener} onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza partener</option>
                                                    <option value="Ward Ashby Studio SRL">Ward Ashby Studio SRL</option>
                                                    <option value="Grantbox SRL">Grantbox SRL</option>
                                                    <option value="Nedefinit">Nedefinit</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        {/*<Row className="mb-3">*/}
                                        {/*    <Label htmlFor="example-partener-input" className="col-md-2 col-form-label">Partener*</Label>*/}
                                        {/*    <Col md={10}>*/}
                                        {/*        <Input type="text" name="partener" placeholder="Introdu Partener" id="example-partener-input" onChange={onChangeInput}  />*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={4}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><b>Alte info</b></h4>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Tip somer*</Label>
                                            <Col md={9}>
                                                <select className="form-select" name="tipSomer" value={formData.tipSomer} onChange={onChangeInput}>
                                                    <option defaultValue="Somer pe o perioada mai mare de 12 luni" >Somer pe o perioada mai mare de 12 luni ( pentru cei cu varsta cuprinsa intre 25- 29 de ani)</option>
                                                    <option value="Nu">Nu</option>
                                                    <option value="Somer pe o perioada de min 6 luni">Somer pe o perioada de min 6 luni(pentru cei cu varsta cuprinsa intre 16-24 de ani si pana la implinirea varstei de 25 de ani)</option>

                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-dataajofm-input" className="col-md-3 col-form-label">Somer din data*</Label>
                                            <Col md={9}>
                                                <div style={{position: "relative"}}>
                                                    <DatePicker
                                                        name="dataAjofm"
                                                        className="form-control"
                                                        selected={formData.dataAjofm === '' || formData.dataAjofm === null ? null : new Date(formData.dataAjofm.toLocaleString())}
                                                        onChange={(event) => handleDateChange(event, "dataAjofm")}
                                                        dateFormat="dd-MM-yyyy"
                                                        autoComplete="off"
                                                        placeholderText="ZZ-LL-AAAA"
                                                    />
                                                    <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Reprezentare*</Label>
                                            <Col md={9}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="reprezentare" checked={formData.reprezentare === "da"} type="checkbox" value="" id="defaultCheck2" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck2">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-numereprezentare-input" className="col-md-3 col-form-label">Nume Reprezentare</Label>
                                            <Col md={9}>
                                                <Input type="text" className={formData.reprezentare === "da" && formData.numeReprezentare.length === 0 ? "is-invalid" : ""} name="numeReprezentare" value={formData.numeReprezentare} placeholder="Introdu nume reprezentare" id="example-numereprezentare-input" onChange={onChangeInput}  />
                                                <div className="invalid-feedback">
                                                    Nume reprezentare nu poate fi un camp gol.
                                                </div>
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Stare sanatate*</Label>
                                            <Col md={9}>
                                                <select className="form-select" name="stareSanatate"  value={formData.stareSanatate} onChange={onChangeInput}>
                                                    <option defaultValue="nu">Nu am restricţii medicale care mă împiedică să prestez muncă</option>
                                                    <option value="da">am restricţii medicale care mă împiedică să prestez o anumită muncă, după cum urmează **)</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label htmlFor="example-detaliistaresanatate-input" className="col-md-3 col-form-label">Detalii stare sanatate</Label>
                                            <Col md={9}>
                                                <Input type="text" name="detaliiStareSanatate" value={formData.detaliiStareSanatate} placeholder="Introdu detalii stare sanatate" id="example-detaliistaresanatate-input" onChange={onChangeInput}  />
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Etnie roma</Label>
                                            <Col md={9}>
                                                <select className="form-select" name="etnieRoma" value={formData.etnieRoma} onChange={onChangeInput}>
                                                    <option disabled selected hidden>Selecteaza etnie</option>
                                                    <option value="Da">Da</option>
                                                    <option value="Nu">Nu</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={4}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title" style={{backgroundColor: "black", color: "white", maxWidth: "184px"}}><b>Alte date GT Sectiunea A</b></h4>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Categorie GT</Label>
                                            <Col md={9}>
                                                <select className="form-select" name="categorieGt" value={formData.categorieGt} onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza categorie</option>
                                                    <option value="Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma">Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma</option>
                                                    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, înregistrați la Serviciul Public de Ocupare și cu rezidența în regiunile eligibile, cu accent pe cei din mediul rural și cei aparținând minorității roma">Tineri NEETs șomeri, cu vârsta între 16-29 ani, înregistrați la Serviciul Public de Ocupare și cu rezidența în regiunile eligibile, cu accent pe cei din mediul rural și cei aparținând minorității roma</option>
                                                    <option value="Tineri NEETs inactivi, cu vârsta între 16-29 ani, neînregistrați la SPO, cu accent pe aceia cu nivel scăzut de competențe și care au dificultăți în a se integra social">Tineri NEETs inactivi, cu vârsta între 16-29 ani, neînregistrați la SPO, cu accent pe aceia cu nivel scăzut de competențe și care au dificultăți în a se integra social</option>
                                                    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității rome">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității rome</option>
                                                    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome.">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome.</option>
                                                    <option value="Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome, inclusiv tineri șomer(...)">Tineri NEETs șomeri, cu vârsta între 16-29 ani, cu domiciliul sau reședința în regiunile eligibile, înregistrați și profilați de către SPO, cu accent pe tinerii NEET din mediul rural și cei aparținând minorității rome, inclusiv tineri șomer(...)</option>
                                                    <option value="Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma">Tineri NEETs şomeri cu vârsta între 16 - 29 ani, înregistrați la Serviciul Public de Ocupare cu rezidența în regiunile eligibile, cu accent pe tinerii NEETs din mediul rural și cei aparținând minorității roma</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        {/*<FormGroup row className="mb-3">*/}
                                        {/*    <Label className="col-md-3 col-form-label">Categorie GT</Label>*/}
                                        {/*    <Col md={9}>*/}
                                        {/*        <select className="form-select" name="categorieGt" value={formData.categorieGt} onChange={onChangeInput}>*/}
                                        {/*            <option value="" disabled selected hidden>Selecteaza categorie</option>*/}
                                        {/*            <option value="Studenți (ISCED 5-7)">Studenți (ISCED 5-7)</option>*/}
                                        {/*            <option value="Doctoranzi în ciclul de studii universitare de doctorat">Doctoranzi în ciclul de studii universitare de doctorat</option>*/}
                                        {/*            <option value="Cercetători post-doctorat">Cercetători post-doctorat</option>*/}
                                        {/*            <option value="Cursanți (ISCED  4, nivel de calificare 5, înmatriculați în colegiile organizate la nivelul instituțiilor de învățământ superior)">Cursanți (ISCED  4, nivel de calificare 5, înmatriculați în colegiile organizate la nivelul instituțiilor de învățământ superior)</option>*/}
                                        {/*            <option value="Angajatori">Angajatori</option>*/}
                                        {/*            <option value="Elevi (ISCED 2 – 3, nivel de calificare 3 - 4)">Elevi (ISCED 2 – 3, nivel de calificare 3 - 4)</option>*/}
                                        {/*            <option value="Elevi (ISCED  4, nivel de calificare 5, înmatriculați în școlile post-liceale/ de maiștri organizate la nivelul unităților de învățământ)">Elevi (ISCED  4, nivel de calificare 5, înmatriculați în școlile post-liceale/ de maiștri organizate la nivelul unităților de învățământ)</option>*/}
                                        {/*            <option value="Ucenici">Ucenici</option>*/}
                                        {/*            <option value="Personalul didactic din învățământul terțiar (universitar, non universitar) și secundar">Personalul didactic din învățământul terțiar (universitar, non universitar) și secundar</option>*/}
                                        {/*            <option value="Formatori ">Formatori </option>*/}
                                        {/*            <option value="Personalul din întreprinderi cu atribuții in învățarea la locul de muncă">Personalul din întreprinderi cu atribuții in învățarea la locul de muncă</option>*/}
                                        {/*        </select>*/}
                                        {/*    </Col>*/}
                                        {/*</FormGroup>*/}
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Situatia pe piata FM</Label>
                                            <Col md={9}>
                                                <select className="form-select" name="situatiePePiataFm"  value={formData.situatiePePiataFm} onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza categorie</option>
                                                    <option value="1">Angajat</option>
                                                    <option value="2">Somer</option>
                                                    <option value="3">Angajat pe cont propriu</option>
                                                    <option value="4">Somer de lunga durata</option>
                                                    <option value="5">Persoana inactiva inclusiv copii anteprescolari, prescolari, elevi, etc</option>
                                                    <option value="6">Alta categorie de inactivi inafara de cei din educatie si formare</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <Row className="mb-3">
                                            <Label className="col-md-10 col-form-label">Inscris intr-un program de educatie</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="inscrisInEducatie" checked={formData.inscrisInEducatie === "da"} type="checkbox" value="" id="defaultCheck3" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck3">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label className="col-md-10 col-form-label">Inscris intr-un program de formare</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="inscrisInFormare" checked={formData.inscrisInFormare === "da"} type="checkbox" value="" id="defaultCheck4" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck4">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <FormGroup row className="mb-3">
                                            <Label className="col-md-3 col-form-label">Nivel educatie</Label>
                                            <Col md={9}>
                                                <select className="form-select" name="nivelEducatie"  value={formData.nivelEducatie} onChange={onChangeInput}>
                                                    <option value="" disabled selected hidden>Selecteaza nivel educatie</option>
                                                    <option value="0">Studii educatie timpurie (ISCED 0)</option>
                                                    <option value="1">Studii primare (ISCED 1)</option>
                                                    <option value="2">Studii gimnaziale (ISCED 2)</option>
                                                    <option value="3">Studii liceale (ISCED 3)</option>
                                                    <option value="4">Studii postliceale (ISCED 4)</option>
                                                    <option value="5">Studii superioare (ISCED 5)</option>
                                                    <option value="6">Studii superioare (ISCED 6)</option>
                                                    <option value="7">Studii superioare (ISCED 7)</option>
                                                    <option value="8">Studii superioare (ISCED 8)</option>
                                                    <option value="9">fara ISCED</option>
                                                </select>
                                            </Col>
                                        </FormGroup>

                                        <Row className="mb-3">
                                            <Label className="col-md-10 col-form-label">Persoana dezavantajata</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="persoanaDezavantajata" checked={formData.persoanaDezavantajata === "da"} type="checkbox" value="" id="defaultCheck4" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck4">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <h4 className="card-title"><b>Grupuri vulnerabile</b></h4>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Participanti care traiesc in gospodarii fara persoane ocupate</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="participantiFaraPersoaneOcupate" checked={formData.participantiFaraPersoaneOcupate === "da"} type="checkbox" value="" id="defaultCheck4" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck4">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Participanti care traiesc in gospodarii fara persoane ocupate cu copii aflati in intretinere</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="participantiCuCopiiInIntretinere" checked={formData.participantiCuCopiiInIntretinere === "da"} type="checkbox" value="" id="defaultCheck5" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck5">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Participantii care traiesc in gospodarii alcatuite dintr-un parinte unic cu copii aflati in intretinere</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="participantiParinteUnic"  checked={formData.participantiParinteUnic === "da"} type="checkbox" value="" id="defaultCheck6" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck6">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Migranti</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="migranti" type="checkbox" checked={formData.migranti === "da"} value="" id="defaultCheck7" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck7">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Participanti de origine straina</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="participantiOrgineStraina" checked={formData.participantiOrgineStraina === "da"} type="checkbox" value="" id="defaultCheck8" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck8">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Minoritati de etnie Roma</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="minoritatiEtnieRoma" checked={formData.minoritatiEtnieRoma === "da"} type="checkbox" value="" id="defaultCheck9" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck9">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Alte minoritati decat cele de etnie Roma</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="alteMinoritati" checked={formData.alteMinoritati === "da"} type="checkbox" value="" id="defaultCheck10" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck10">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Persoane cu dizabilitati</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="persoaneCuDizabilitati"  checked={formData.persoaneCuDizabilitati === "da"} type="checkbox" value="" id="defaultCheck11" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck11">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Alte categorii de persoane dezavantajate</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="alteCategorii" type="checkbox"  checked={formData.alteCategorii === "da"} value="" id="defaultCheck12" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck12">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Persoane fara adapost sau care sunt afectate de excluziunea locativa</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="persoaneFaraAdapost"  checked={formData.persoaneFaraAdapost === "da"} type="checkbox" value="" id="defaultCheck13" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck13">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={1}></Col>
                                            <Label className="col-md-9 col-form-label">Apartin la comunitati marginalizate</Label>
                                            <Col md={2}>
                                                <div className="form-check mb-3">
                                                    <Input className="form-check-input" name="apartinComunitatiMarginalizate"  checked={formData.apartinComunitatiMarginalizate === "da"} type="checkbox" value="" id="defaultCheck14" onChange={onChangeInput}  />
                                                    <Label className="form-check-label" htmlFor="defaultCheck14">
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={7}></Col>
                            <Col xs={2} align="center">
                                {location.pathname !== "/formular" && (
                                    <Button
                                        color="dark"
                                        className="waves-effect waves-light me-1"
                                        onClick={startDeleteProcess}
                                    >
                                        Sterge
                                    </Button>
                                )}

                            </Col>
                            <Col xs={2} align="center">
                                <Button
                                    color="dark"
                                    className="waves-effect waves-light me-1"
                                    type="submit"
                                >
                                    Salveaza
                                </Button>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                </form>
                    <br/>
                    <br/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Formular;
