import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Spinner, Table} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link, useLocation} from "react-router-dom";
import CandidatiBar from "./CandidatiBar";
import axiosInstance from "../../helpers/AxiosHelper";
import FileDownload from "js-file-download";
import {atom, useAtom} from "jotai";
import {idAtom} from "../Cursuri/TableComponent";
import DatePicker from "react-datepicker";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import SecondaryNavbar from "../../components/HorizontalLayout/SecondaryNavbar";

// export const idAtom = atom(null);


const Documente = () => {
    const [fileToSend, setFileToSend] = useState(null);
    const [candidateFiles, setCandidateFiles] = useState([]);
    // const location = useLocation();
    // const idParam = location.pathname.substring(11);
    const [atomId, setAtomId] = useAtom(idAtom);
    const [docType, setDocType] = useState(null);
    const [docDate, setDocDate] = useState('');
    const [docTypeToGenerate, setDocTypeToGenerate] = useState(null);
    const [candidateGeneratedFiles, setCandidateGeneratedFiles] = useState([]);
    const [candidate, setCandidate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [finalDoc, setFinalDoc] = useState("Draft");
    const [generatedFileIdToDelete, setGeneratedFileIdToDelete] = useState(null);
    const [scanFileIdToDelete, setScanFileIdToDelete] = useState(null);
    const [candidateFileIdToDelete, setCandidateFileIdToDelete] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    async function getFiles() {
        await axiosInstance.get('/api/candidate/files', {
            params: {
                id: atomId
            },
            // responseType:"blob"
        }).then(r => {
            setCandidateFiles(r.data);
            // setLoading(false);
        })
    }
    async function getGeneratedFiles() {
        await axiosInstance.get('/api/candidate/generatedfiles', {
            params: {
                id: atomId
            },
            // responseType:"blob"
        }).then(r => {
            setCandidateGeneratedFiles(r.data);
            // setLoading(false);
        })
    }

    async function getCandidate() {
        await axiosInstance.get('/api/candidate/id', {
            params: {
                id: atomId
            },
            // responseType:"blob"
        }).then(r => {
            setCandidate(r.data);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getCandidate()
        getFiles()
        getGeneratedFiles()

    },[]);

    const breadcrumbItems = [
        { title : "Forms", link : "#" },
        { title : "Form Validation", link : "#" },
    ];


    function handleSubmit(e) {
        e.preventDefault();
        setIsGenerating(true);
        const formData = new FormData();
        console.log(docDate)
        formData.append("date", docDate);
        formData.append("type", docTypeToGenerate);
        formData.append("finalDoc", finalDoc);
        formData.append("id", atomId);

        try {
            let preview = "";
            axiosInstance.post(`/api/candidate/generate${docTypeToGenerate === "Toate documentele" ? "/zip" : ""}`, formData, {
                // responseType:"blob"
            }).then(res => {
                const response = res.data;
                console.log(response)
                // preview = response.preview;
                if (!(res.status === 200)) {
                    // preview = res.preview;
                    throw new Error(`Error! status: ${res.status}`);
                }
                setIsGenerating(false);

                getGeneratedFiles();
                // FileDownload(response, docTypeToGenerate, response.type)
            }).catch((err) => {
                console.log(err.response.data)
                setIsGenerating(false);
                toastr.error(`${err.response.data}`, "")
            })
        } catch(error) {
            console.log(error)
        }

    }

    function onChangeInput(e) {
        console.log(e.target.type)
        if (e.target.type === "select-one") {
            console.log(e.target.value)
            setDocTypeToGenerate(e.target.value)
        }
        else if (e.target.type === "checkbox") {
            console.log("sunt in checkbox")
            setFinalDoc(e.target.checked ? "Final" : "Draft");
        }
        else {
            console.log("sunt in else")
            setDocTypeToGenerate(e.target.value)
        }
    }

    function handleFileSubmit() {

    }

    async function uploadFile(e) {
        e.preventDefault();
        setFileToSend(e.target.files[0]);
        console.log(e.target.files[0])
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", atomId);
        formData.append("type", docType);
        console.log(formData)
        try {

            await axiosInstance.post('/api/candidate/add/file', formData, {
                headers: {"Content-Type": "multipart/form-data"},
            }).then(res => {
                if (!(res.status === 200 || res.status === 201)) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                toastr.success(`Documentul a fost incarcat!`, "")
            }).catch(() => toastr.error(`Documentul nu a fost incarcat! Selecteaza tipul documentului.`, ""))

        } catch (error) {
            console.log(error)
        }
        getFiles();
    }

    async function handleFileUpload(e) {
        e.preventDefault();
    }

    function downloadFile(e, file) {
        e.preventDefault();
        axiosInstance.get(`/api/candidatefile/download/${file.id}`, {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            console.log(response)
            FileDownload(response, file.fileName, response.type)
        })
    }

    function onChangeDocType(e) {
        console.log(e.target.value)
        setDocType(e.target.value)
    }

    function downloadGeneratedFile(e, file) {
        e.preventDefault();
        console.log(file.id)
        axiosInstance.get(`/api/candidate/generatedfile/download/${file.id}`, {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            console.log(response)
            FileDownload(response, file.fileName, response.type)
        })
    }

    if (successConfirm) {
        console.log("intra in stergere")
        if (generatedFileIdToDelete !== null) {
            try {
                axiosInstance.delete('/api/candidate/generatedfile/delete/id', {
                    params: {
                        id: generatedFileIdToDelete
                    },
                }).then(res => {
                    if (!(res.status === 200)) {
                        throw new Error(`Error! status: ${res.status}`);
                    }
                    const response = res.data;
                    console.log(response)
                    getGeneratedFiles();
                })
            } catch(error) {
                console.log(error)
            }
        } else if (scanFileIdToDelete !== null) {
            try {
                axiosInstance.delete('/api/scanfile/delete/id', {
                    params: {
                        id: scanFileIdToDelete
                    },
                }).then(res => {
                    if (!(res.status === 200)) {
                        throw new Error(`Error! status: ${res.status}`);
                    }
                    const response = res.data;
                    console.log(response)
                    getGeneratedFiles();
                })
            } catch(error) {
                console.log(error)
            }
        } else if (candidateFileIdToDelete !== null) {
            try {
                axiosInstance.delete('/api/candidatefile/delete/id', {
                    params: {
                        id: candidateFileIdToDelete
                    },
                }).then(res => {
                    if (!(res.status === 200)) {
                        throw new Error(`Error! status: ${res.status}`);
                    }
                    const response = res.data;
                    console.log(response)
                    getFiles();
                })
            } catch(error) {
                console.log(error)
            }
        }
        setCandidateFileIdToDelete(null);
        setScanFileIdToDelete(null);
        setGeneratedFileIdToDelete(null);
        setSuccessConfirm(false)
    }

    function handleDelete(e, file) {
        e.preventDefault();
        setIsAlert(true);
        setGeneratedFileIdToDelete(file.id)
        // try {
        //     axiosInstance.delete('/api/candidate/generatedfile/delete/id', {
        //         params: {
        //             id: file.id
        //         },
        //     }).then(res => {
        //         if (!(res.status === 200)) {
        //             throw new Error(`Error! status: ${res.status}`);
        //         }
        //         const response = res.data;
        //         console.log(response)
        //         getGeneratedFiles();
        //     })
        // } catch(error) {
        //     console.log(error)
        // }
    }

    function handleFileDelete(e, file) {
        e.preventDefault();
        setIsAlert(true);
        setCandidateFileIdToDelete(file.id);
        // try {
        //     axiosInstance.delete('/api/candidatefile/delete/id', {
        //         params: {
        //             id: file.id
        //         },
        //     }).then(res => {
        //         if (!(res.status === 200)) {
        //             throw new Error(`Error! status: ${res.status}`);
        //         }
        //         const response = res.data;
        //         console.log(response)
        //         getFiles();
        //     })
        // } catch(error) {
        //     console.log(error)
        // }
    }

    async function uploadScanFile(e, file) {
        e.preventDefault();
        console.log(e.target.files[0]);
        console.log(file)
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("id", file.id);
        try {
            await axiosInstance.post('/api/scanfile/add/file', formData, {
                headers: {"Content-Type": "multipart/form-data"},
            }).then(res => {
                if (!(res.status === 200 || res.status === 201 )) {
                    throw new Error(`Error! status: ${res.status}`);
                }
                toastr.success(`Documentul a fost incarcat!`, "")
            }).catch(() => toastr.error(`Documentul nu a fost incarcat!`, ""))
        } catch (error) {
            console.log(error)
        }
        getGeneratedFiles();
    }

    function downloadScanFile(e, file) {
        e.preventDefault();
        console.log(file.id)
        axiosInstance.get(`/api/scanfile/download/${file.id}`, {
            responseType: "blob"
        }).then((res) => {
            const response = res.data;
            console.log(response)
            FileDownload(response, "scan_" + file.fileName, response.type)
        })
    }

    function deleteScanFile(e, file) {
        e.preventDefault();
        setIsAlert(true);
        setScanFileIdToDelete(file.id);
        // try {
        //     axiosInstance.delete('/api/scanfile/delete/id', {
        //         params: {
        //             id: file.id
        //         },
        //     }).then(res => {
        //         if (!(res.status === 200)) {
        //             throw new Error(`Error! status: ${res.status}`);
        //         }
        //         const response = res.data;
        //         console.log(response)
        //         getGeneratedFiles();
        //     })
        // } catch(error) {
        //     console.log(error)
        // }
    }

    function getDateFormat(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('ro-RO', {year: 'numeric', month: '2-digit', day: '2-digit'});
    }

    const handleDateChange = (event) => {
        console.log(event)
        const localDate = new Date(event);
        const year = localDate.getFullYear();
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        console.log(formattedDate)
        setDocDate(formattedDate);
    };

    if(isLoading) {
        return (<div></div>)
    }

    return (
        <React.Fragment>
            <SecondaryNavbar />
            {/*<CandidatiBar page="Documente" />*/}
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }
                    }
                    onCancel={() => {
                        setIsAlert(false);
                    }
                    }
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            <div className="page-content" style={{paddingTop: "100px"}}>
                <Container fluid>

                    <Breadcrumbs title={candidate.nume + " " + candidate.prenume} breadcrumbItems={breadcrumbItems} />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title"><b>DOCUMENTE GENERATE</b></h4>
                                        <form onSubmit={handleSubmit}>
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Tip document</Label>
                                                <Col md={5}>
                                                    <select className="form-select" name="tipDocument" onChange={onChangeInput}>
                                                        <option value="" disabled selected hidden>Selecteaza tip document</option>
                                                        <option value="Toate documentele">Toate documentele</option>
                                                        <option value="Anexa_1_Fisa inscriere in proiect">Anexa_1_Fisa inscriere in proiect</option>
                                                        <option value="Anexa_2_Declaratie dubla finantare">Anexa_2_Declaratie dubla finantare</option>
                                                        <option value="Anexa_3_Scrisoare angajam_disponibilitate">Anexa_3_Scrisoare angajam_disponibilitate</option>
                                                        <option value="Anexa_4_Declaratie_status_curent">Anexa_4_Declaratie_status_curent</option>
                                                        <option value="Anexa_5_Declaratie_somer">Anexa_5_Declaratie_somer</option>
                                                        <option value="Anexa_6_Declaratie roma">Anexa_6_Declaratie roma</option>
                                                        <option value="ANEXA 2_Fisa de informare fata in fata">ANEXA 2_Fisa de informare fata in fata</option>
                                                        <option value="ANEXA 5_Cerere de preinscriere">ANEXA 5_Cerere de preinscriere</option>
                                                        <option value="ANEXA 6_Acord date personale">ANEXA 6_Acord date personale</option>
                                                        <option value="ANEXA 7_Imputernicire reprezentare in relatia AJOFM">ANEXA 7_Imputernicire reprezentare in relatia AJOFM</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                            <Row className="mb-3">
                                                <Label htmlFor="example-datadocument-input" className="col-md-1 col-form-label">Data Document</Label>
                                                <Col md={3}>
                                                    <div style={{position: "relative"}}>
                                                        <DatePicker
                                                            name="dataDocument"
                                                            className="form-control"
                                                            selected={docDate === '' ? null : new Date(docDate)}
                                                            onChange={(event) => handleDateChange(event)}
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="Selecteaza data"
                                                            autoComplete="off"
                                                            minDate={new Date(candidate.dataIntrareGt)}
                                                        />
                                                        <div style={{position: "absolute", right: "17px", top: "9px", bottom: "0", margin: "auto"}}>
                                                    <span>
                                                        <i className="far fa-calendar"></i>
                                                    </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Final</Label>
                                                <Col md={10}>
                                                    <div className="form-check mb-3">
                                                        <Input className="form-check-input" name="final" type="checkbox" value="" id="defaultCheck2" onChange={onChangeInput}  />
                                                        <Label className="form-check-label" htmlFor="defaultCheck2">
                                                        </Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={9}></Col>
                                                <Col xs={3} align="center">
                                                    <Button
                                                        color="dark"
                                                        className="waves-effect waves-light me-1"
                                                    >
                                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                            {isGenerating ? <Spinner className="me-2" color="primary" style={{width: "20px", height: "20px"}} /> : <>Genereaza</>}
                                                        </div>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        <h4 className="card-title"><b>Show all</b></h4>
                                        <Row>
                                            <Col xs={12}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-rep-plugin">
                                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                <Table id="tech-companies-1" striped bordered responsive>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="dimenisune-col">Tip doc</th>
                                                                        <th className="dimenisune-col">Data document</th>
                                                                        <th className="dimenisune-col">Status</th>
                                                                        <th className="dimenisune-col">Link regenerare</th>
                                                                        <th className="dimenisune-col">Scan</th>
                                                                        <th className="dimenisune-col">Actiune</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {candidateGeneratedFiles.map((file) => {
                                                                        return(
                                                                            <tr key={file.id}>
                                                                                <td>{file.docType}</td>
                                                                                <td>{file.docDate === "" ? "" : getDateFormat(file.docDate)}</td>
                                                                                <td>{file.finalDoc}</td>
                                                                                <td><Link onClick={e => downloadGeneratedFile(e, file)} style={{cursor: "pointer"}}>Regenereaza</Link></td>
                                                                                {!file.isScan && (
                                                                                    <td>
                                                                                        <label htmlFor="filePicker1"  style={{cursor:"pointer"}}>
                                                                                            Upload
                                                                                            <input id="filePicker1" type="file" name="file1" onChange={e => uploadScanFile(e, file)} style={{display: "none"}}></input>
                                                                                        </label>
                                                                                    </td>
                                                                                )}
                                                                                {file.isScan && (
                                                                                    <td>
                                                                                        <Link onClick={e => downloadScanFile(e, file)} style={{cursor: "pointer"}}>Download</Link>
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                        <i className="fas fa-trash-alt" onClick={e => deleteScanFile(e, file)} style={{cursor: "pointer"}}></i>
                                                                                    </td>

                                                                                )}
                                                                                <td><Link onClick={e => handleDelete(e, file)} style={{cursor: "pointer"}}>Delete</Link></td>
                                                                            </tr>
                                                                        )
                                                                    }) }

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <h4 className="card-title"><b>DOCUMENTE FURNIZATE</b></h4>
                                            <FormGroup row className="mb-3">
                                                <Label className="col-md-1 col-form-label">Tip document</Label>
                                                <Col md={5}>
                                                    <select className="form-select" name="tipDocument" onChange={onChangeDocType}>
                                                        <option value="" disabled selected hidden>Selecteaza tip document</option>
                                                        <option value="Copie CI">Copie CI</option>
                                                        <option value="Diploma ultima scoala absolvita">Diploma ultima scoala absolvita</option>
                                                        <option value="Adeverinta ultima scoala absolvita">Adeverinta ultima scoala absolvita</option>
                                                        <option value="Copie a certificatului de căsătorie">Copie a certificatului de căsătorie</option>
                                                        <option value="Adeverință de vechime">Adeverință de vechime</option>
                                                        <option value="Decizie incetare CIM">Decizie incetare CIM</option>
                                                        <option value="Adeverinta AJOFM">Adeverinta AJOFM</option>
                                                        <option value="Copie a certificatului de nastere">Copie a certificatului de nastere</option>
                                                    </select>
                                                </Col>
                                                <label htmlFor="filePicker" className="col-md-1 col-form-label"  style={{cursor:"pointer"}}>
                                                    Upload
                                                    <input id="filePicker" type="file" name="file" onChange={uploadFile} style={{display: "none"}}></input>
                                                </label>
                                            </FormGroup>
                                        </Row>

                                        <Row>
                                            <Col xs={6}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-rep-plugin">
                                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                <Table id="tech-companies-1" striped bordered responsive>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="dimenisune-col">Tip doc</th>
                                                                        <th className="dimenisune-col">Fisier</th>
                                                                        <th className="dimenisune-col">Actiune</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {candidateFiles.map((file) => {
                                                                        return(
                                                                            <tr key={file.id}>
                                                                                <td>{file.docType}</td>
                                                                                <td><Link onClick={e => downloadFile(e, file)} style={{cursor: "pointer"}}>Download</Link></td>
                                                                                <td><Link onClick={e => handleFileDelete(e, file)} style={{cursor: "pointer"}}>Delete</Link></td>
                                                                            </tr>
                                                                        )
                                                                    }) }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                    <br/>
                    <br/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Documente;
