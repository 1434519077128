import React, { useEffect, useState } from 'react'
import { Input, Table } from 'reactstrap'
import {Link} from "react-router-dom";
import {atom, useAtom} from "jotai";
import axiosInstance from "../../helpers/AxiosHelper";
import toastr from "toastr";
import FileDownload from "js-file-download";
import SweetAlert from "react-bootstrap-sweetalert";

function getDateFormat(receivedData, dateString) {
    console.log(dateString)
    const date = new Date(dateString);
    return date.toLocaleDateString('ro-RO', {year: 'numeric', month: '2-digit', day: '2-digit'});
}

function getAge(receivedData, dataIntrareGt, cnp) {
    const dataReferinta = new Date(dataIntrareGt);
    const birthDate = new Date((cnp.charAt(0) === '1' || cnp.charAt(0) === '2' ? "19" : "20") + cnp.charAt(1) + cnp.charAt(2) + "-" + cnp.charAt(3) + cnp.charAt(4) +
        "-" + cnp.charAt(5) + cnp.charAt(6) + "T00:00:00.000Z");
    return dataReferinta.getFullYear() - birthDate.getFullYear() + " ani";
}

function findCourseDenumire(receivedData, candidateId, isCursPrincipal) {
    const candidate = receivedData.find(candidate => candidate.id === candidateId);
    const course = candidate.courses.find(element => element.principal === isCursPrincipal);
    if (course !== undefined) {
        return course.denumire
    }
    return "-";
}

function findProvider(receivedData, candidateId, isCursPrincipal) {
    const candidate = receivedData.find(candidate => candidate.id === candidateId);
    const course = candidate.courses.find(element => element.principal === isCursPrincipal);
    if (course !== undefined) {
        const session = candidate.sessions.find(session => session.coursePrv.id === course.id);
        if (session !== undefined) {
            return session.provider.nume
        }
    }

    return "-";
}

function findDataStartCurs(receivedData, candidateId,isCursPrincipal) {
    const candidate = receivedData.find(candidate => candidate.id === candidateId);
    const course = candidate.courses.find(element => element.principal === isCursPrincipal);
    if (course !== undefined) {
        const session = candidate.sessions.find(session => session.coursePrv.id === course.id);
        if (session !== undefined) {
            console.log("data sesiune: " + session.dataStart + " la candidat: " + candidate.nume)
            return formatDate(session.dataStart)
        }
    }

    return "-";
}

function findDataFinalCurs(receivedData, candidateId, isCursPrincipal) {
    const candidate = receivedData.find(candidate => candidate.id === candidateId);
    const course = candidate.courses.find(element => element.principal === isCursPrincipal);
    if (course !== undefined) {
        const session = candidate.sessions.find(session => session.coursePrv.id === course.id);
        if (session !== undefined) {
            return formatDate(session.dataFinal)
        }
    }

    return "-";
}

function formatDate(fullDateString) {
    let date = new Date(fullDateString)
    let yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return dd + '.' + mm + '.' + yyyy;
}

function passesFilters(data, filters) {
    for (let i = 0; i < Math.min(data.length, filters.length); i++) {
        if (filters[i] !== '' && ` ${data[i]}`.toLowerCase().search(`${filters[i]}`.toLowerCase()) === -1) return false
    }
    return true
}

export const idAtom = atom(null);




function TableComponent(props) {
    const { fnc, receivedData, tableHeadings, tableType, setReceivedData } = props
    const [sortBy, setSortBy] = useState({ column: 0, direction: 1 })
    const [filters, setFilters] = useState(Array.from({ length: tableHeadings.length }, () => ''))
    const [filteredData, setFilteredData] = useState([])
    const [atomId, setAtomId] = useAtom(idAtom);


    const [candidateIdToDelete, setCandidateIdToDelete] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [successConfirm, setSuccessConfirm] = useState(false);
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [dynamicDescription, setDynamicDescription] = useState('');

    useEffect(() => {
        setFilteredData(prepareInputData(receivedData,tableType));
    }, [receivedData,tableType])

    function SortColumnsBy(index) {
        let newArray = filteredData.sort(function (a, b) {
            return sortBy.direction * (`${b[index]}`.localeCompare(`${a[index]}`));
        })
        setFilteredData(newArray);
        setSortBy({ column: index, direction: -(sortBy.direction) })
    }

    function handleChange(index, target) {
        let newFilters = [...filters]
        newFilters[index] = target
        setFilters(newFilters)
    }

    function getClassNameOfRow(label) {
        if (tableType === "Candidati") {
            if (label === "CNP" || label === 0) return "sticky-col first-col";
            else if (label === "Nume" || label === 1) return "sticky-col second-col";
            else if (label === "Prenume" || label === 2) return "sticky-col third-col";
        }
        return "dimenisune-col";
    }

    function getCandidates() {
        axiosInstance.get('/api/candidate/all').then((response) => {
            setReceivedData(response.data);
        })
    }


    async function deleteBusinessPlan() {
        if (candidateIdToDelete) {
            try {
                const response = await axiosInstance.delete(`/api/businessplan/${candidateIdToDelete}`);
                if (response?.status === 200) {
                    getCandidates()
                } else {
                    throw new Error(`Error! status: ${response?.status}`);
                }
            } catch (error) {
                console.log(error);
                toastr.error('Documentul nu a fost sters! Contacteaza echipa suport!', '');
            }
        }
    }

    if (successConfirm) {
        deleteBusinessPlan()
        setCandidateIdToDelete(null)
        setSuccessConfirm(false)
    }


    function prepareInputData(receivedData,tableType) {
        let result = [[]]

        function getMinutes(mediations) {
            let minutes = 0
            mediations.forEach((mediation) => minutes += mediation.nrMinute)
            return minutes / 60;
        }

        function getGen(cnp) {
            if (cnp[0] === "1" || cnp[0] === "5") return "M"
            if (cnp[0] === "2" || cnp[0] === "6") return "F"
            return "Cnp incorect";
        }

        async function uploadFile(e, candidateId) {
            e.preventDefault();
            const formData = new FormData();
            formData.append("file", e.target.files[0]);
            formData.append("id", candidateId);
            try {
                const response = await axiosInstance.post("/api/businessplan", formData,
                    {headers: { "Content-Type": "multipart/form-data" },
                    });
                if (response.status === 200 || response.status === 201) {
                    getCandidates()
                    toastr.success("Documentul a fost incarcat!", "");
                }
                else throw new Error(`Error! status: ${response.status}`);

            } catch (error) {
                toastr.error("Documentul nu a fost incarcat! A aparut o eroare.", "");
                console.log(error);
            }
        }

        async function downloadFile(e, candidate) {
            e.preventDefault();
            try {
                const { data } = await axiosInstance.get(`/api/businessplan/${candidate.id}`, {
                    responseType: 'blob',
                });
                FileDownload(data, "plan_de_afaceri_" + candidate.nume + "_" + candidate.prenume, data.type);
            } catch (error) {
                console.error(error);
                toastr.error('Descarcarea a esuat! Contacteaza echipa suport!', '');
            }
        }

        function deleteFile(e, candidateId) {
            e.preventDefault();
            setIsAlert(true);
            setCandidateIdToDelete(candidateId);
        }


        switch (tableType) {
            case "Sesiuni":
                result = receivedData.map(function (item) {return item&&[item.id, item.coursePrv.denumire, item.provider.nume, formatDate(item.dataStart), formatDate(item.dataFinal), item.locatie, item.nrLocuri, item.empty]})
                break;
            case "PlanuriAfaceri":
                result = receivedData.map(function (candidate) {return candidate&&[candidate.nume,
                    candidate.prenume,
                    candidate.isBusinessPlan ?
                        <>
                            <Link onClick={e => downloadFile(e, candidate)} style={{cursor: "pointer"}}>Download</Link>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <i className="fas fa-trash-alt" onClick={(e) => deleteFile(e, candidate.id)} style={{cursor: "pointer"}}></i>
                        </>
                        :
                        <label htmlFor="filePicker" style={{cursor:"pointer"}}>
                            Upload
                            <input id="filePicker" type="file" name="file" onChange={(e) => uploadFile(e, candidate.id)} style={{display: "none"}}></input>
                        </label>,
                    candidate.id]})
                break;
            case "Candidati":
                result = receivedData.map(function (candidate) {return candidate && [
                    candidate.cnp,
                    candidate.nume,
                    candidate.prenume,
                    candidate.judet,
                    candidate.mediu,
                    candidate.judetResedinta,
                    candidate.mediuResedinta,
                    candidate.etnieRoma,
                    candidate.categorieGt,
                    candidate.dataIntrareGt === "" ? "-" : getDateFormat(receivedData, candidate.dataIntrareGt),
                    candidate.dataIntrareGt === "" || candidate.cnp.length < 7 ? "-" : getAge(receivedData, candidate.dataIntrareGt, candidate.cnp),
                    candidate.profil, candidate.dataStartMediere === "" ? "-" : getDateFormat(receivedData, candidate.dataStartMediere),
                    candidate.dataEndMediere === "" ? "-" : getDateFormat(receivedData, candidate.dataEndMediere),
                    // candidate.mediations.length,
                    // getMinutes(candidate.mediations),
                    "-",
                    "-",
                    "???",
                    findDataStartCurs(receivedData, candidate.id, "da"),
                    findDataFinalCurs(receivedData, candidate.id, "da"),
                    candidate.courses.length,
                    findCourseDenumire(receivedData, candidate.id, "da"),
                    findProvider(receivedData, candidate.id, "da"),
                    "???",
                    findDataStartCurs(receivedData, candidate.id, "nu"),
                    findDataFinalCurs(receivedData, candidate.id, "nu"),
                    findCourseDenumire(receivedData, candidate.id, "nu"),
                    findProvider(receivedData, candidate.id, "nu"),
                    "???",
                    candidate.dataIesireGt === "" ? "-" : getDateFormat(receivedData, candidate.dataIesireGt),
                    candidate.dataPlataSubventie === "" ? "-" : getDateFormat(receivedData, candidate.dataPlataSubventie),
                    getGen(candidate.cnp), candidate.email, candidate.telefon, candidate.partener, candidate.tipSomer, "???", candidate.id]})
                break;
            default :
                result = [[]]
        }
        return result
    }

    return (
        <React.Fragment>
            {isAlert && (
                <SweetAlert
                    title="Esti sigur ca vrei sa stergi?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setSuccessConfirm(true);
                        setDynamicTitle("Deleted!");
                        setDynamicDescription("Your file has been deleted.");
                        setIsAlert(false);
                    }}
                    onCancel={() => {
                        setIsAlert(false);
                    }}
                >
                    Nu mai poti recupera informatia!
                </SweetAlert>
            )}
            <div className="table-rep-plugin" >
                <div className="table-responsive mb-0" data-pattern="priority-columns" >
                    <Table id="tech-companies-1" striped bordered responsive>
                        <thead>
                        <tr style={{position: "relative"}}>
                            {tableHeadings.map((label, index) => (<th className={getClassNameOfRow(label)} key={index} onClick={() => SortColumnsBy(index)}>
                                {label}
                                <div style={{position: "absolute", right: "8px", top: tableType === "Candidati" ? "50%" : "25%", bottom: "0", margin: "auto"}}>
                                    <span>
                                        <i className="fas fa-sort"></i>
                                    </span>
                                </div>

                            </th>))}
                        </tr>
                        <tr>
                            {tableHeadings.map((label, index) => (<th className={getClassNameOfRow(label)} key={index}>
                                <Input type="text" name={label} placeholder="" id={index} onChange={(event) => handleChange(index, event.target.value)} />
                            </th>))}
                        </tr>
                        </thead>
                        <tbody>
                        {filteredData.map((filterRowData, sessionIndex) => (passesFilters(filterRowData, filters) &&
                            <tr key={sessionIndex} onClick={() => {
                                console.log("returnez randul din received data care are id = id-ul randului pe care sunt")
                                fnc(receivedData.find(obj => obj&&obj.id === filterRowData[0]))}}>

                                {filterRowData.map((detail, detailIndex) => detailIndex !== filterRowData.length - 1 &&
                                    <td className={getClassNameOfRow(detailIndex)} key={detailIndex} >{tableType === "Candidati" && detailIndex === 0 ? <Link to={"/formular-candidati/"} onClick={() => setAtomId(filterRowData[filterRowData.length - 1])}>{detail}</Link>  : detail}</td>)}
                            </tr>
                        ))}

                        </tbody>
                    </Table>
                </div>
            </div>

        </React.Fragment>

    )
}

export default TableComponent
