import React from 'react';
import {Col, Collapse, Container, Row} from "reactstrap";
import {idAtom} from "../../pages/Cursuri/TableComponent";
import {Link, useLocation} from "react-router-dom";
import classname from "classnames";
import {useAtom} from "jotai";

const SecondaryNavbar = () => {
    const [atomId, setAtomId] = useAtom(idAtom);
    let location = useLocation();

    return (
        <React.Fragment>
            <div className="topnav">
                <Container fluid>
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">

                        <Collapse isOpen={true} className="navbar-collapse" id="topnav-menu-content">

                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    {atomId !== null && (
                                        <Link className="nav-link" to="/formular-candidati">
                                            <i className="ri-dashboard-line me-2"></i> Formular
                                        </Link>
                                    )}
                                </li>
                                <li className="nav-item">
                                    {atomId !== null && (
                                        <Link className="nav-link" to="/documente">
                                            <i className="ri-dashboard-line me-2"></i> Documente
                                        </Link>
                                    )}
                                </li>
                                <li className="nav-item">
                                    {atomId !== null && (
                                        <Link className="nav-link" to="/date-raportare">
                                            <i className="ri-dashboard-line me-2"></i> Date Raportare
                                        </Link>
                                    )}
                                </li>
                                <li className="nav-item">
                                    {atomId !== null && (
                                        <Link className="nav-link" to="/mediere">
                                            <i className="ri-dashboard-line me-2"></i> Mediere
                                        </Link>
                                    )}
                                </li>
                                <li className="nav-item">
                                    {atomId !== null && (
                                        <Link className="nav-link" to="/cursuri-candidati">
                                            <i className="ri-dashboard-line me-2"></i> Cursuri
                                        </Link>
                                    )}
                                </li>
                                <li className="nav-item">
                                    {atomId !== null && (
                                        <Link className="nav-link" to="/profil">
                                            <i className="ri-dashboard-line me-2"></i> Profil Detaliat
                                        </Link>
                                    )}
                                </li>
                                <li className="nav-item">
                                    {(location.pathname === "/tabel-candidati" || location.pathname === "/nomenclatoare" || location.pathname === "/formular-candidati" || location.pathname === "/planuri-afaceri") && (
                                        <Link className="nav-link" to="/" >PLATFORMA NEETS SUD MUNTENIA
                                        </Link>
                                    )}
                                </li>
                                {(location.pathname === "/cursuri" || location.pathname === "/sesiuni" ||
                                    location.pathname === "/alocare-cursuri" || location.pathname === "/rezultate-cursuri") && (
                                        <>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/cursuri" >
                                                    <i className="ri-dashboard-line me-2"></i> Cursuri
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/sesiuni" >
                                                    <i className="ri-dashboard-line me-2"></i> Sesiuni
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/alocare-cursuri" >
                                                    <i className="ri-dashboard-line me-2"></i> Alocare Cursuri
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/rezultate-cursuri" >
                                                    <i className="ri-dashboard-line me-2"></i> Rezultate Cursuri
                                                </Link>
                                            </li>
                                        </>


                                    )}
                            </ul>
                        </Collapse>
                    </nav>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SecondaryNavbar;
